import { EditorLanguage } from '../editorLanguage';
import LanguageService from '../services/LanguageService';
import { FoliaLexer } from './antlr/FoliaLexer';
import { FoliaParser } from './antlr/FoliaParser';
import FoliaState from './FoliaState';
import FoliaTokenFactory from './FoliaTokenFactory';
import FoliaTokensProvider from './FoliaTokensProvider';
import FoliaPredicate, { createTrivialPredicate } from './model/predicate';

class FoliaLanguageService extends LanguageService<
  EditorLanguage.FOLIA,
  FoliaState,
  FoliaLexer,
  FoliaParser
> {
  static readonly LANGUAGE_ID = EditorLanguage.FOLIA;

  constructor(debugLogging = false) {
    super(
      FoliaLanguageService.LANGUAGE_ID,
      FoliaParser.VOCABULARY,
      FoliaState,
      new FoliaTokenFactory(),
      FoliaTokensProvider,
      FoliaLexer,
      FoliaParser,
      debugLogging,
    );
  }

  getPredicate = (input: string | null): FoliaPredicate =>
    input === null
      ? createTrivialPredicate()
      : {
          code: input,
          tokens: this.getSimpleTokens(input),
        };
}

export default FoliaLanguageService;
