// @ts-strict-ignore
import { useState } from 'react';
import { RechartsFunction } from '../../shared/dataTypes';

//hooks
export const useChartOpacity = (initialOpacity: {
  [key: string]: number;
}): [
  { [key: string]: number },
  (opacity: { [key: string]: number }) => void,
  (headers: string[]) => RechartsFunction,
  () => void,
] => {
  const [opacity, setOpacity] = useState(initialOpacity);

  const onChartEnter = (headers: string[]): RechartsFunction => {
    return ({ value }) => {
      const newOpacity: { [key: string]: number } = {};
      headers.forEach(el => (newOpacity[el] = value === el ? 1 : 0.3));
      setOpacity(newOpacity);
    };
  };

  const onChartLeave = () => {
    const newOpacity: { [key: string]: number } = {};
    for (const key in opacity) {
      if (opacity.hasOwnProperty(key)) {
        newOpacity[key] = 1;
      }
    }
    setOpacity(newOpacity);
  };

  return [opacity, setOpacity, onChartEnter, onChartLeave];
};

export const getExtrema = (
  rawValues: Array<number | string | null | undefined>,
): [number, number] => {
  const values = rawValues
    .map(value => (typeof value === 'number' ? value : parseInt(value, 10)))
    .filter(value => !isNaN(value));

  return [Math.min(...values), Math.max(...values)];
};
