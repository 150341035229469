import { createObject } from 'algo-react-dataviz';
import deepEqual from 'deep-equal';
import { v4 as uuid } from 'uuid';
import { StyleProps } from './styleProps';
import {
  BaseStyleTarget,
  CellStyleTarget,
  ColumnStyleTarget,
  createStyleTargetsForSelection,
  getHash,
  NormalGroupingStyleTarget,
  RowStyleTarget,
  StyleTarget,
  styleTargetApplies,
  styleTargetSupersedes,
  StyleTargetType,
  StyleTargetTypeForSelection,
  styleTargetTypesForSelection,
} from './styleTarget';

export interface BaseStyleRule extends BaseStyleTarget {
  timeCreated: number; // ms since Unix epoch
  styleProps: StyleProps;
  styleId: string;
}

export type CellStyleRule = BaseStyleRule & CellStyleTarget;
export type RowStyleRule = BaseStyleRule & RowStyleTarget;
export type ColumnStyleRule = BaseStyleRule & ColumnStyleTarget;
export type NormalGroupingStyleRule = BaseStyleRule & NormalGroupingStyleTarget;

export type StyleRule = CellStyleRule | RowStyleRule | ColumnStyleRule | NormalGroupingStyleRule;

export const createStyleRule = (target: StyleTarget, styleProps: StyleProps): StyleRule => ({
  ...target,
  timeCreated: Date.now(),
  styleId: uuid(),
  styleProps,
});

// Right now, AWA/IWA determines the style rule precedence (cf. `styleTargetSupersedes`).
// Until determining precedence by time created is supported, this is unused.
// export const orderByTimeCreated = (rules: StyleRule[]) =>
// [...rules].sort((ruleA, ruleB) => ruleB.timeCreated - ruleA.timeCreated);

export const addUniformStyles = (initialStyles: StyleRule[], newStyles: StyleRule[]) => [
  ...newStyles,
  ...removeUniformStyles(initialStyles, newStyles),
];

export const removeUniformStyles = (initialStyles: StyleRule[], removedTargets: StyleRule[]) => {
  const removedTargetHashes = removedTargets.map(getStyleRuleHash);

  return initialStyles.filter(style => !removedTargetHashes.includes(getStyleRuleHash(style)));
};

export const removeUniformStyleTargets = (
  initialStyles: StyleRule[],
  removedTargets: StyleTarget[],
) => {
  const removedTargetHashes = removedTargets.map(getHash);

  return initialStyles.filter(style => !removedTargetHashes.includes(getHash(style)));
};

const getStyleRuleHash = (rule: StyleRule) =>
  `${getHash(rule)}${!!rule.styleProps?.conditionalStyleProps ? rule.styleId : ''}${
    !!rule.styleProps?.heatMapStyleProps ? 'heatmap' : ''
  }`;

// If `requiredType` is null, any type is okay.
export const getFirstApplicableStyleRule = (
  rules: StyleRule[],
  target: StyleTarget,
  requiredType: StyleTargetType | null = null,
) =>
  rules.reduce<StyleRule | null>(
    (currentRule, nextRule) =>
      (requiredType === null || nextRule.type === requiredType) &&
      styleTargetApplies(nextRule, target) &&
      !nextRule.styleProps.conditionalStyleProps &&
      (currentRule === null || styleTargetSupersedes(nextRule, currentRule))
        ? nextRule
        : currentRule,
    null,
  );

export const getFirstSharedApplicableStyleRule = (rules: StyleRule[], targets: StyleTarget[]) => {
  if (rules.length === 0 || targets.length === 0) {
    return null;
  }

  const applicableRules = targets.map(target => getFirstApplicableStyleRule(rules, target));

  return !applicableRules.every(rule => deepEqual(rule, applicableRules[0]))
    ? null
    : applicableRules[0];
};

export const getCopyableStylePropsForSelection = (
  rules: StyleRule[],
  cellIds: string[],
): Record<StyleTargetTypeForSelection, StyleProps | null> =>
  createObject<StyleTargetTypeForSelection, StyleTargetType, StyleProps | null>({
    items: styleTargetTypesForSelection,
    createValue: type =>
      cellIds.length === 1
        ? getFirstApplicableStyleRule(rules, createStyleTargetsForSelection(type, cellIds)[0], type)
            ?.styleProps ?? null
        : null,
  });
