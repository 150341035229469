// @ts-strict-ignore
import { Modifier } from 'algo-react-dataviz';
import { DraggableLocation } from 'react-beautiful-dnd';
import { v4 as uuid } from 'uuid';
import {
  Characteristic,
  DroppableState,
  LayerDefinition,
  ReportDefinition,
} from '../../../shared/dataTypes';
import { isCharEqualToLayer } from '../../../shared/utils';
import { GroupingLayerId } from './groupingLayerId';

export const DESIGNER_AVAILABLE_CHARS = 'DESIGNER_AVAILABLE_CHARS';
export const CHARACTERISTICS_ID = 'chars';
export const VERTICAL_ID = 'verticalChars';
export const FILTER_CHARS = 'filterChars';
export const HORIZONTAL_ID = 'horizontalChars';
export const CUSTOM_GROUPING_ID = 'customGrouping';

export const reorder = <T>(list: T[], startIndex: number, endIndex: number): T[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const copy = (
  source: Characteristic[],
  destination: Characteristic[],
  droppableSource: DraggableLocation,
  droppableDestination: DraggableLocation,
) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const item = sourceClone[droppableSource.index];

  destClone.splice(droppableDestination.index, 0, item);
  return destClone;
};

export const move: (
  source: Characteristic[],
  destination: Characteristic[],
  droppableSource: DraggableLocation,
  droppableDestination: DraggableLocation,
) => DroppableState = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

export const defaultHorizChar: Characteristic = {
  name: 'Characteristics',
  charId: GroupingLayerId.CHARACTERISTIC,
  classes: null,
  isGroupingLayer: true,
  draggableId: uuid(),
  dataType: null,
  modifier: Modifier.PORT,
};

export const initialDroppableState: DroppableState = {
  chars: [],
  verticalChars: [],
  horizontalChars: [defaultHorizChar],
  filterChars: [],
};

export const createDroppableState = (
  allChars: Characteristic[],
  chars: ReportDefinition['chars'],
  verticalChars: LayerDefinition[],
  horizontalChars: LayerDefinition[],
): DroppableState => {
  const characteristicGenerator = (c: LayerDefinition) => {
    const {
      linkedGroupingPath,
      grouperData,
      breakpoints,
      layerId,
      customGrouping,
      groupingListId,
      styleProps,
    } = c;

    return {
      ...allChars.concat(defaultHorizChar).find(d => isCharEqualToLayer(d, c)),

      // IMPORTANT: When copied from available chars to a different droppable, a draggable
      // must be given a new draggableId, or you will get buggy behavior and this warning:
      // "Detected non-consecutive <Draggable /> indexes."
      draggableId: uuid(),

      ...(linkedGroupingPath
        ? { linkedGroupingPath, name: linkedGroupingPath.split('/').pop() }
        : {}),

      ...(grouperData ? { grouperData } : {}),
      ...(breakpoints ? { breakpoints } : {}),
      ...(groupingListId ? { groupingListId } : {}),
      ...(styleProps ? { styleProps } : {}),

      ...(layerId === GroupingLayerId.CUSTOM_GROUPING
        ? {
            charId: layerId,
            id: customGrouping.id,
            name: customGrouping.name ?? 'Ad Hoc Custom Grouping',
            customGrouping, // TODO: SD-2534
          }
        : {}),
    };
  };

  return allChars.length
    ? {
        chars:
          chars?.map(c => ({
            ...allChars
              .concat(defaultHorizChar)
              .find(d => d.charId === c.charId && d.modifier === c.modifier),
            draggableId: uuid(),
          })) ?? [],
        verticalChars: verticalChars?.map(characteristicGenerator) ?? [],
        horizontalChars: horizontalChars?.map(characteristicGenerator) ?? [],
      }
    : initialDroppableState;
};
