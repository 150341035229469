import { SimpleToken } from '../../SimpleToken';

interface FoliaPredicate {
  code: string | null;
  tokens: SimpleToken[];
}

export const createTrivialPredicate = (): FoliaPredicate => ({
  code: null,
  tokens: [],
});

export default FoliaPredicate;
