// @ts-strict-ignore
import { defaultReportDesignerBenchmarks } from '../shared/constants';
import { Benchmark } from '../shared/dataTypes';
import * as ActionTypes from './ActionTypes';

const defaultState: [Benchmark, Benchmark] = defaultReportDesignerBenchmarks;

export const benchmarks = (state: [Benchmark, Benchmark] = defaultState, action): Benchmark[] => {
  switch (action.type) {
    case ActionTypes.SET_REPORT_DESIGNER_BENCHMARKS:
      return { ...action.payload.benchmarks };

    case ActionTypes.SET_BENCHMARK: {
      const benchmarks: [Benchmark, Benchmark] = { ...state };
      benchmarks[action.payload.index] = {
        ...benchmarks[action.payload.index],
        [action.payload.propertyName]: action.payload.propertyValue,
      };

      return [benchmarks[0], benchmarks[1]];
    }

    case ActionTypes.SET_INDIVIDUAL_BENCHMARK: {
      const { index, path, benchmark } = action.payload;
      const oldBenchmarks = state[index].individualBenchmarks ?? [];

      const individualBenchmarks = oldBenchmarks.some(b => b.path === path)
        ? oldBenchmarks.map(b => (b.path === path ? { ...b, benchmark } : b))
        : [...oldBenchmarks, { path, benchmark }];

      return state.map((b, i) => (i === index ? { ...state[i], individualBenchmarks } : b));
    }

    case ActionTypes.SET_AD_HOC_BENCHMARK: {
      const { index, benchInfo } = action.payload;
      const oldBenchmarks = state[index].adHocBenchmarks;

      const adHocBenchmarks = oldBenchmarks.some(b => b.id === benchInfo.id)
        ? oldBenchmarks.map(b =>
            b.id === benchInfo.id ? { ...b, benchmark: benchInfo.benchmark } : b,
          )
        : [...oldBenchmarks, { id: benchInfo.id, benchmark: benchInfo.benchmark }];

      return state.map((b, i) => (i === index ? { ...b, adHocBenchmarks } : b));
    }

    case ActionTypes.COPY_BENCHMARKS:
      return action.payload || defaultState;
    default:
      return state;
  }
};
