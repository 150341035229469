// @ts-strict-ignore
import { createObject } from 'algo-react-dataviz';
import { createContext, useContext, useMemo } from 'react';
import AScriptLanguageService from '../ascript/AScriptLanguageService';
import { allEditorLanguages, EditorLanguage } from '../editorLanguage';
import FoliaLanguageService from '../folia/FoliaLanguageService';
import LanguageService from './LanguageService';

type BaseEditorLanguages = {
  [Language in EditorLanguage]: LanguageService<Language> | null;
};

export interface LanguageServices extends BaseEditorLanguages {
  [EditorLanguage.ASCRIPT]: AScriptLanguageService | null;
  [EditorLanguage.JAVA]: null;
  [EditorLanguage.FOLIA]: FoliaLanguageService | null;
}

export const createTrivialLanguageServices = (): LanguageServices =>
  createObject({
    items: allEditorLanguages,
    createValue: () => null,
  });

export const LanguageServiceContext = createContext<LanguageServices>(
  createTrivialLanguageServices(),
);

export const useLanguageServices = () => {
  const {
    [EditorLanguage.ASCRIPT]: aScriptLanguageService,
    [EditorLanguage.FOLIA]: foliaLanguageService,
  } = useContext(LanguageServiceContext);

  return useMemo(
    () => ({
      [EditorLanguage.ASCRIPT]: aScriptLanguageService,
      [EditorLanguage.JAVA]: null as null,
      [EditorLanguage.FOLIA]: foliaLanguageService,
    }),
    [aScriptLanguageService, foliaLanguageService],
  );
};
