// @ts-strict-ignore
import { FC, useEffect, useState } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  XAxisProps,
  YAxis,
  YAxisProps,
} from 'recharts';
import { paletteT50 as colors } from '../shared/colorPalettes';
import { legendWrapperStyles } from '../shared/constants';
import { DataConverters } from '../shared/dataConverters';
import { ReportConvertedData, ReportProps } from '../shared/dataTypes';
import { useChartOpacity } from './helpers/chartingHelpers';

const MixedBarChartViz: FC<ReportProps> = props => {
  const [opacity, , onChartEnter, onChartLeave] = useChartOpacity({});
  const [reportConvertedData, setReportConvertedData] = useState<ReportConvertedData>();

  useEffect(() => setReportConvertedData(DataConverters.MIXED_BAR_CHART(props.reportRawData)), [
    props.reportRawData,
  ]);

  if (props.width <= 0 || props.height <= 0 || !reportConvertedData) {
    return null;
  }

  const mixedBarData = reportConvertedData.rows;

  const isInverted = props.isInverted;

  const xAxisProps: XAxisProps = isInverted
    ? { type: 'number' }
    : { dataKey: reportConvertedData.groupingHeader.name };
  const yAxisProps: YAxisProps = isInverted
    ? { dataKey: reportConvertedData.groupingHeader.name, type: 'category' }
    : {};

  return (
    <BarChart
      stackOffset='sign'
      layout={isInverted ? 'vertical' : 'horizontal'}
      width={props.width}
      height={props.height}
      data={mixedBarData}
      margin={{ left: 30 }}
      onClick={event =>
        event &&
        props.onElementClick(
          event.activePayload[0].payload[`${event.activePayload[0].name}rowcol`],
        )(event)
      }
    >
      <CartesianGrid strokeDasharray='3 3' />
      <XAxis {...xAxisProps} />
      <YAxis {...yAxisProps} />
      <Tooltip formatter={(value, name, props) => props.payload[`${name}Formatted`]} />
      <Legend
        layout='vertical'
        align='right'
        verticalAlign='top'
        wrapperStyle={legendWrapperStyles.vertical}
        onMouseEnter={onChartEnter(reportConvertedData.headers)}
        onMouseLeave={onChartLeave}
      />
      <Bar
        dataKey={reportConvertedData.headers[0]}
        stackId='a'
        fill={colors[0]}
        fillOpacity={opacity[reportConvertedData.headers[0]] || 1}
      />
      {reportConvertedData.headers[1] && (
        <Bar
          dataKey={reportConvertedData.headers[1]}
          stackId='a'
          fill={colors[1]}
          fillOpacity={opacity[reportConvertedData.headers[1]] || 1}
        />
      )}
      {reportConvertedData.headers[2] && (
        <Bar
          dataKey={reportConvertedData.headers[2]}
          fill={colors[2]}
          fillOpacity={opacity[reportConvertedData.headers[2]] || 1}
        />
      )}
    </BarChart>
  );
};

export default MixedBarChartViz;
