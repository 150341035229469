import { Modifier } from 'algo-react-dataviz';
import axios from 'axios';
import { v4 } from 'uuid';
import { DataSettingsTab } from '../../components/data-settings/dataSettingsTabs';
import { GroupingLayerId } from '../../components/designer-panel/drag-and-drop/groupingLayerId';
import { ContentPage } from '../../components/main/PageContent/contentPage';
import { baseUrl } from '../../components/shared/environment';
import { NotificationLevel } from '../../shared/constants';
import { Characteristic } from '../../shared/dataTypes';
import { enqueueSnackbar } from '../ActionCreators';
import { AppThunk } from '../configureStore';
import {
  availableCharacteristicsPopulated,
  emptyListsDeleted,
  listRenamed,
  listsPopulated,
} from './reducer';
import { getChangedGroupingLists, getDeletedGroupingLists, getNewGroupingLists } from './selectors';

export type GroupingListChar = {
  charId: number;
  customGroupingId?: string;
  grouperData?: string[];
  breakpoints?: string[];
};

type GroupingListToCreate = { name: string; chars: GroupingListChar[] };
type GroupingListToCreateDto = GroupingListToCreate[];
type GroupingListDto = (GroupingListToCreate & { id: string })[];

export const isGrpListCharIdEqualToCharID = (
  groupingList: GroupingListChar | undefined | null,
  char: Characteristic | undefined | null,
) =>
  (groupingList?.charId === GroupingLayerId.CUSTOM_GROUPING
    ? groupingList.customGroupingId
    : groupingList?.charId) ===
  (char?.charId === GroupingLayerId.CUSTOM_GROUPING ? char.id : char?.charId);

export const getGroupingListsDataIfOpen = (): AppThunk => (dispatch, getState) =>
  getState().ui.activePage === ContentPage.DATA_SETTINGS &&
  getState().groupingLists.tab === DataSettingsTab.GROUPING_LIST_EDITOR &&
  dispatch(getGroupingListsData());

export const getGroupingListsData = (): AppThunk => async dispatch => {
  try {
    const { data: availableChars } = await axios.get<Characteristic[]>(
      `${baseUrl}api/availableCharacteristics`,
    );

    dispatch(
      availableCharacteristicsPopulated(
        availableChars.map(c => ({ ...c, draggableId: v4(), modifier: Modifier.PORT })),
      ),
    );

    dispatch(getGroupingLists());
  } catch (error) {
    dispatch(enqueueSnackbar(NotificationLevel.ERROR, 'Error getting available characteristics.'));
    console.error(error);
  }
};

export const getGroupingLists = (): AppThunk => async (dispatch, getState) => {
  try {
    const { data: savedGroupingLists } = await axios.get<GroupingListDto>(
      `${baseUrl}api/groupingList`,
    );

    dispatch(
      listsPopulated(
        savedGroupingLists
          .map(list => ({
            id: list.id,
            name: list.name,
            chars:
              (list.chars?.map(c => ({
                ...getState().groupingLists.availableCharacteristics.find(a =>
                  isGrpListCharIdEqualToCharID(c, a),
                ),
                breakpoints: c.breakpoints,
                grouperData: c.grouperData,
                draggableId: v4(),
              })) as Characteristic[]) ?? [],
          }))
          .sort((a, b) => (a.name > b.name ? 1 : -1)),
      ),
    );
  } catch (error) {
    dispatch(enqueueSnackbar(NotificationLevel.ERROR, 'Error getting grouping lists.'));
    console.error(error);
  }
};

const toGroupingListChar = ({
  charId,
  id,
  grouperData,
  breakpoints,
}: Characteristic): GroupingListChar => ({
  charId,
  customGroupingId: id,
  grouperData,
  breakpoints,
});

export const saveGroupingLists = (): AppThunk => (dispatch, getState) => {
  dispatch(emptyListsDeleted());
  const changedGroupingLists = getChangedGroupingLists(getState());
  const newGroupingLists = getNewGroupingLists(getState());
  const deletedGroupingLists = getDeletedGroupingLists(getState());

  const axiosCalls = [
    ...(changedGroupingLists.length > 0
      ? [
          axios.put<never, never, GroupingListDto>(
            `${baseUrl}api/groupingList`,
            changedGroupingLists.map(list => ({
              id: list.id,
              name: list.name,
              chars: list.chars.map(toGroupingListChar),
            })),
          ),
        ]
      : []),

    ...(newGroupingLists.length > 0
      ? [
          axios.post<never, never, GroupingListToCreateDto>(
            `${baseUrl}api/groupingList`,
            newGroupingLists.map(list => ({
              name: list.name,
              chars: list.chars.map(toGroupingListChar),
            })),
          ),
        ]
      : []),

    ...(deletedGroupingLists.length > 0
      ? deletedGroupingLists.map(({ id }) =>
          axios.delete(`${baseUrl}api/groupingList`, { params: { id } }),
        )
      : []),
  ];

  Promise.allSettled(axiosCalls).then(result => {
    if (!result.some(r => r.status === 'rejected')) {
      dispatch(enqueueSnackbar(NotificationLevel.SUCCESS, 'Grouping lists saved.'));
    } else if (!result.some(r => r.status === 'fulfilled')) {
      dispatch(enqueueSnackbar(NotificationLevel.ERROR, 'All grouping list changes failed.'));
    } else {
      dispatch(enqueueSnackbar(NotificationLevel.ERROR, 'Some grouping list changes failed.'));
    }

    dispatch(getGroupingLists());
  });
};

export const renameList = (
  listId: string,
  newName: string,
  blur: () => void,
  focus: () => void,
): AppThunk => (dispatch, getState) => {
  if (getState().groupingLists.unsavedGroupingLists.some(u => u.name === newName)) {
    dispatch(
      enqueueSnackbar(NotificationLevel.ERROR, 'A grouping list with that name already exists.'),
    );
    focus();
  } else {
    dispatch(listRenamed({ listId, newName }));
    blur();
  }
};
