export enum DesignerSource {
  CREATE_BLANK,

  OPEN_FROM_WORKSPACE,
  CLONE_FROM_WORKSPACE,

  OPEN_WITH_DRILL_THROUGH,

  OPEN_DETACHED,
}

export const creatingNewReport = (source: DesignerSource) =>
  [
    DesignerSource.CREATE_BLANK,
    DesignerSource.CLONE_FROM_WORKSPACE,
    DesignerSource.OPEN_WITH_DRILL_THROUGH,
    DesignerSource.OPEN_DETACHED,
  ].includes(source);
