// @ts-strict-ignore
import deepEqual from 'deep-equal';
import { v4 as uuid } from 'uuid';
import { CUSTOM_GROUPING_DEBUG } from '../../components/shared/environment';
import { DisplayFilterDefinition, StadiumVersion } from '../../shared/dataTypes';
import createExampleGroupingTree from './exampleCustomGrouping';
import GroupingNodeController from './groupingNodeController';
import {
  GroupingStrategy,
  GroupingStrategyDto,
  GroupingStrategyType,
  transformFromDto as transformStrategyFromDto,
  transformToDto as transformStrategyToDto,
} from './groupingStrategy';

/**
 * Front-end models
 */

export interface CustomGrouping {
  readonly id: string;
  readonly name: string | null;
  readonly description: string | null;
  readonly owner: string | null;
  readonly rootNode: GroupingNode;
}

export interface GroupingNode {
  readonly id: string;
  readonly name: string;
  readonly groupingStrategy: GroupingStrategy | null;
  readonly children: GroupingNode[];
  readonly displayFilter?: DisplayFilterDefinition;
  readonly priority?: number;
}

export const createTrivialCustomGrouping = (): CustomGrouping => ({
  id: uuid(),
  name: null,
  description: null,
  owner: null,
  rootNode: CUSTOM_GROUPING_DEBUG ? createExampleGroupingTree() : createTrivialGroupingTree(),
});

export const ROOT_NODE_NAME = 'Total';
export const createTrivialGroupingTree = () =>
  GroupingNodeController.create({
    name: ROOT_NODE_NAME,
  }).serialize();

export const isAdHoc = ({ name }: CustomGrouping) => name === null;

/**
 * API DTOs
 */

export interface CustomGroupingDto {
  readonly id: string;
  readonly name: string | null;
  readonly description: string | null;
  readonly owner: string | null;
  readonly nodes: GroupingNodeDto[];
  readonly version?: StadiumVersion;
}

export interface GroupingNodeDto {
  readonly id: string;
  readonly parentId: string | null;
  readonly name: string | null;
  readonly groupingStrategy: GroupingStrategyDto | null;
  readonly displayFilter?: DisplayFilterDefinition | null;
  readonly priority?: number;
}

/**
 * Transformations
 */

export const transformToDto = ({
  id,
  name,
  description,
  owner,
  rootNode,
}: CustomGrouping): CustomGroupingDto => ({
  id,
  name,
  description,
  owner,
  nodes: flattenNode(rootNode),
});

export const flattenNode = (
  { id, name, groupingStrategy, displayFilter, priority, children }: GroupingNode,
  parentId: string | null = null,
): GroupingNodeDto[] => [
  {
    id,
    parentId,
    name,
    groupingStrategy: transformStrategyToDto(groupingStrategy),
    displayFilter,
    priority,
  },
  ...children.flatMap(child => flattenNode(child, id)),
];

export const transformFromDto = ({
  id,
  name,
  description,
  owner,
  nodes,
}: CustomGroupingDto): CustomGrouping => ({
  id,
  name,
  description,
  owner,
  rootNode: treeify(nodes).find(
    ({ groupingStrategy }) => groupingStrategy?.type !== GroupingStrategyType.REMAINDER,
  ),
});

export const treeify = (nodes: GroupingNodeDto[], id: string | null = null): GroupingNode[] =>
  nodes
    .filter(node => node.parentId === id)
    .map(node => ({
      id: node.id,
      name: node.name,
      groupingStrategy: transformStrategyFromDto(node.groupingStrategy),
      displayFilter: node.displayFilter,
      priority: node.priority,
      children: treeify(nodes, node.id),
    }));

/**
 * Invertibility tests
 */

export const testModelInvertibility = (grouping: CustomGrouping) =>
  deepEqual(grouping, transformFromDto(transformToDto(grouping)), {
    strict: true,
  });

export const testDtoInvertibility = (groupingDto: CustomGroupingDto) =>
  deepEqual(groupingDto, transformToDto(transformFromDto(groupingDto)), {
    strict: true,
  });
