import {
  createPointChartData,
  DxPointChart,
  DxPointChartType,
  PointChartData,
  useUuid,
} from 'algo-react-dataviz';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { updateElementSelection } from '../redux/ActionCreators';
import { VisualizationComponent } from './visualizationComponents';

const createDxPointChartViz = (chartType: DxPointChartType): VisualizationComponent => props => {
  const dispatch = useDispatch();
  const id = useUuid();

  const { sequenceId, reportRawData } = props;
  const data = useMemo<PointChartData | null>(() => {
    return !reportRawData ? null : createPointChartData(chartType, reportRawData);
  }, [reportRawData]);

  const onSelectedIdsChange = useCallback(
    (selectedIds: string[]) => {
      dispatch(updateElementSelection(sequenceId, selectedIds));
    },
    [dispatch, sequenceId],
  );

  return !data ? null : (
    <DxPointChart
      {...{
        id,
        chartType,
        data,
        onSelectedIdsChange,
      }}
      hideLegend={data.seriesSignatures.length === 1}
      selectionMode='modifierDependent'
      selectedIds={props.selectedElements ?? []}
    />
  );
};

export default createDxPointChartViz;
