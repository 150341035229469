// @ts-strict-ignore
import { DESIGNER_SEQUENCE_ID } from '../shared/constants';
import {
  ParentToChildRelationship,
  WorkspaceData,
  WorkspacePayload,
  WorkspaceState,
} from '../shared/dataTypes';
import { findTabId, findWorkspaceBottom, maxTabId, minTabId } from '../shared/utils';
import { setWorkspaceReportSelection } from './ActionCreators';
import * as ActionTypes from './ActionTypes';

export const defaultWorkspace: WorkspaceData = {
  path: null,
  tabs: {
    1: {
      name: 'Untitled',
      reports: {},
      layouts: {},
    },
  },
  selectedPortfolioNodeIds: [],
  selectedAdHocPortfolioNames: [],
  selectedBenchmarkPortfolioNames: [],
  selectedEntities: {
    selectedCounterparties: [],
    selectedOwnEntities: [],
  },
};

export const workspace = (
  state: WorkspaceState = {
    selectedTabIndex: 1,
    maximizedLayerId: null,
    data: defaultWorkspace,
    currency: { id: 'USD' }, // Temporarily setting initial currency value until there is a default currency in place.
    parentToChildRelationship: {},
  },
  action,
): WorkspaceState => {
  switch (action.type) {
    case ActionTypes.ADD_NEW_TAB:
      return {
        ...state,
        selectedTabIndex: maxTabId(state.data.tabs) + 1,
        maximizedLayerId: null,
        data: {
          ...state.data,
          tabs: {
            ...state.data.tabs,
            [maxTabId(state.data.tabs) + 1]: { name: 'Untitled', reports: {}, layouts: {} },
          },
        },
      };

    case ActionTypes.REMOVE_TAB: {
      const tabIdToRemove: number = action.payload.tabId;

      const { [tabIdToRemove]: removedTab, ...remainingTabs } = state.data.tabs;

      if (Object.keys(remainingTabs).length === 0) {
        // the tab removed was the only tab. we need to put the default workspace back in
        return { ...state, data: defaultWorkspace };
      } else {
        const tabKeys = Object.keys(state.data.tabs).map(key => Number(key));
        const currIdx = tabKeys.findIndex(x => x === state.selectedTabIndex);
        const removedIdx = tabKeys.findIndex(x => x === tabIdToRemove);

        let newIdx = 0;
        if (currIdx > removedIdx) {
          // Removed tab is to the left of the currently selected tab. Preserve current selection.
          newIdx = state.selectedTabIndex;
        } else if (currIdx < Object.keys(remainingTabs).length) {
          // The current selection is still valid in the remaining tabs.
          newIdx = Number(Object.keys(remainingTabs)[currIdx]);
        } else {
          // Removed tab is the last tab. Select prior tab.
          newIdx = Number(Object.keys(remainingTabs)[Object.keys(remainingTabs).length - 1]);
        }

        return {
          ...state,
          selectedTabIndex: newIdx,
          maximizedLayerId: null,
          data: { ...state.data, tabs: remainingTabs },
        };
      }
    }
    case ActionTypes.CLEAR_TAB: {
      const tabIdToClear: number = action.payload.tabId;
      const { [tabIdToClear]: tabToClear, ...remainingTabs } = state.data.tabs;

      return {
        ...state,
        data: {
          ...state.data,
          tabs: { ...remainingTabs, [tabIdToClear]: { ...tabToClear, reports: {} } },
        },
      };
    }

    case ActionTypes.SET_TABS: {
      return {
        ...state,
        data: {
          ...state.data,
          tabs: action.payload,
        },
      };
    }

    case ActionTypes.UPDATE_SELECTED_PORTFOLIO_HIERARCHY_NAME: {
      return {
        ...state,
        data: {
          ...state.data,
          selectedPortfolioHierarchyName: action.payload,
        },
      };
    }

    case ActionTypes.RENAME_TAB: {
      const { tabId, name } = action.payload;
      const { [tabId]: tabToUpdate, ...remainingTabs } = state.data.tabs;

      return {
        ...state,
        data: {
          ...state.data,
          tabs: { ...remainingTabs, [tabId]: { ...tabToUpdate, name } },
        },
      };
    }
    case ActionTypes.UPDATE_WORKSPACE_REPORT_PATH: {
      const { sequenceId, reportPath }: { sequenceId: number; reportPath: string } = action.payload;

      // find the tab id that contains the report with sequenceid
      const tabId = findTabId(sequenceId, state.data);
      // destructure the tab of interest
      const { [tabId]: tabToUpdate, ...remainingTabs } = state.data.tabs;

      // destructure the report to update
      const { [sequenceId]: reportToUpdate, ...remainingReports } = tabToUpdate.reports;

      return {
        ...state,
        data: {
          ...state.data,
          tabs: {
            ...remainingTabs,
            [tabId]: {
              ...tabToUpdate,
              reports: {
                ...remainingReports,
                [sequenceId]: { ...reportToUpdate, reportPath },
              },
            },
          },
        },
      };
    }

    case ActionTypes.SET_WORKSPACE_REPORT_HAS_CHANGES: {
      const {
        sequenceId,
        hasChanges,
      }: { sequenceId: number; hasChanges: boolean } = action.payload;

      // find the tab id that contains the report with sequenceid
      const tabId = findTabId(sequenceId, state.data);
      // destructure the tab of interest
      const { [tabId]: tabToUpdate, ...remainingTabs } = state.data.tabs;

      // destructure the report to update
      const { [sequenceId]: reportToUpdate, ...remainingReports } = tabToUpdate.reports;

      return {
        ...state,
        data: {
          ...state.data,
          tabs: {
            ...remainingTabs,
            [tabId]: {
              ...tabToUpdate,
              reports: {
                ...remainingReports,
                [sequenceId]: { ...reportToUpdate, hasChanges },
              },
            },
          },
        },
      };
    }

    case ActionTypes.SET_WORKSPACE_REPORT: {
      const wp = action.payload;
      // find the tab id that contains the report with sequenceid
      const tabId = findTabId(wp.sequenceId, state.data);
      // destructure the tab of interest
      const { [tabId]: tabToUpdate, ...remainingTabs } = state.data.tabs;

      // destructure the report to update
      const { [wp.sequenceId]: reportToUpdate, ...remainingReports } = tabToUpdate.reports;

      return {
        ...state,
        data: {
          ...state.data,
          tabs: {
            ...remainingTabs,
            [tabId]: {
              ...tabToUpdate,
              reports: {
                ...remainingReports,
                [wp.sequenceId]: wp,
              },
            },
          },
        },
      };
    }
    case ActionTypes.UPDATE_WORKSPACE_REPORT_SELECTION: {
      const {
        payload: { sequenceId, selectedIds },
      }: ReturnType<typeof setWorkspaceReportSelection> = action;

      // find the tab id that contains the report with sequenceid
      const tabId = findTabId(sequenceId, state.data);
      // destructure the tab of interest
      const { [tabId]: tabToUpdate, ...remainingTabs } = state.data.tabs;

      // destructure the report to update
      const { [sequenceId]: reportToUpdate, ...remainingReports } = tabToUpdate.reports;

      return {
        ...state,
        data: {
          ...state.data,
          tabs: {
            ...remainingTabs,
            [tabId]: {
              ...tabToUpdate,
              reports: {
                ...remainingReports,
                [sequenceId]: { ...reportToUpdate, selectedElements: selectedIds },
              },
            },
          },
        },
      };
    }

    case ActionTypes.SET_PERSIST_WORKSPACE_PORTFOLIO_SELECTION: {
      const persistPortfolioSelection: boolean = action.payload;

      return {
        ...state,
        persistPortfolioSelection,
      };
    }

    case ActionTypes.SET_PARENT_TO_CHILD_RELATIONSHIP: {
      const {
        parentToChildRelationship,
        isAppendMode,
      }: {
        parentToChildRelationship: ParentToChildRelationship;
        isAppendMode: boolean;
      } = action.payload;

      if (
        isAppendMode &&
        state.parentToChildRelationship &&
        Object.keys(state.parentToChildRelationship).length > 0
      ) {
        // I already have some parentToChildRelationships and we are appending a new workspace. We need to merge the existing ones with the new ones
        const updatedParentToChildRelationship = { ...state.parentToChildRelationship };

        Object.entries(parentToChildRelationship).forEach(entry => {
          const [sequenceId, children] = entry;
          if (updatedParentToChildRelationship[sequenceId]) {
            updatedParentToChildRelationship[sequenceId] = children.concat(
              updatedParentToChildRelationship[Number(sequenceId)],
            );
          } else {
            updatedParentToChildRelationship[sequenceId] = [...children];
          }
        });

        return {
          ...state,
          parentToChildRelationship: updatedParentToChildRelationship,
        };
      } else {
        // this is the first time we set parentToChildRelationship or appending to an empty workspace/or workspace with no children reports
        return { ...state, parentToChildRelationship: { ...parentToChildRelationship } };
      }
    }
    case ActionTypes.UPDATE_WORKSPACE_STATUS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case ActionTypes.UPDATE_WORKSPACE: {
      const {
        isDrillThrough,
        newWorkspace,
      }: {
        isDrillThrough: boolean;
        newWorkspace: WorkspaceData;
      } = action.payload;

      if (isDrillThrough) {
        // this is a drill through from a report to another workspace
        // instead of replacing the existing workspace with the new one, we need to merge the new one in the old one
        const selectedTabIndex = minTabId(newWorkspace.tabs);

        return {
          ...state,
          isLoading: false,
          errMessage: null,
          data: {
            ...state.data,
            tabs: { ...state.data.tabs, ...newWorkspace.tabs },
          },
          selectedTabIndex,
        };
      } else {
        const selectedTabIndex = minTabId(newWorkspace.tabs);

        return {
          ...state,
          maximizedLayerId: null,
          isLoading: false,
          errMessage: null,
          data: newWorkspace,
          selectedTabIndex,
        };
      }
    }
    case ActionTypes.REMOVE_REPORT_FROM_WORKSPACE: {
      const sequenceId = action.payload.sequenceId;
      // Remove the sequence id from the parentToChildRelationship map.
      // Note that the sequenceId could be both a key (as parent report) and/or a member
      // of the array of values (a child report).
      const {
        [sequenceId]: removedRelationships,
        ...remainingParentToChildRelationships
      } = state.parentToChildRelationship;

      const parentToChildRelationship = {} as ParentToChildRelationship;

      Object.entries(remainingParentToChildRelationships).forEach(entry => {
        const children = entry[1] as number[];
        if (children.includes(sequenceId)) {
          parentToChildRelationship[entry[0]] = children.filter(child => child !== sequenceId);
        } else {
          parentToChildRelationship[entry[0]] = children;
        }
      });

      // find the tabId for the report with sequenceId
      // This will be null if the report has already been removed, e.g. because it was a child
      // of another report in this tab.
      const tabId = findTabId(sequenceId, state.data);

      // destructure the tab which contains the report to be removed
      const { [tabId]: tab, ...remainingTabs } = state.data.tabs;
      // destructure the report to be removed
      const { [sequenceId]: removedReport, ...remainingReports } = tab?.reports || {};

      return {
        ...state,
        maximizedLayerId: null,
        parentToChildRelationship,
        data: {
          ...state.data,
          tabs: tabId
            ? {
                ...remainingTabs,
                [tabId]: { ...state.data.tabs[tabId], reports: remainingReports },
              }
            : state.data.tabs,
        },
      };
    }
    case ActionTypes.ADD_REPORT_TO_WORKSPACE: {
      const {
        workspacePayload,
        parentSequenceId,
        toNewTab,
      }: {
        workspacePayload: WorkspacePayload;
        parentSequenceId: number;
        toNewTab: boolean;
      } = action.payload;

      if (parentSequenceId === DESIGNER_SEQUENCE_ID) {
        // this is adding a regular report
        if (state.data === null) {
          // this is a brand new workspace
          return {
            ...state,
            data: {
              ...state.data,
              tabs: {
                1: {
                  ...defaultWorkspace.tabs[1],
                  reports: {
                    [workspacePayload.sequenceId]: workspacePayload,
                  },
                  layouts: {
                    lg: [{ x: 0, y: 0, h: 16, w: 16, i: workspacePayload.layerId }],
                    md: [{ x: 0, y: 0, h: 16, w: 8, i: workspacePayload.layerId }],
                    sm: [{ x: 0, y: 0, h: 16, w: 4, i: workspacePayload.layerId }],
                  },
                },
              },
            },
          };
        } else {
          // this is an existing workspace
          const { lgY, mdY, smY } = findWorkspaceBottom(state.data.tabs[state.selectedTabIndex]);
          return {
            ...state,
            data: {
              ...state.data,
              tabs: {
                ...state.data.tabs,
                [state.selectedTabIndex]: {
                  ...state.data.tabs[state.selectedTabIndex],
                  reports: {
                    ...state.data.tabs?.[state.selectedTabIndex]?.reports,
                    [workspacePayload.sequenceId]: workspacePayload,
                  },
                  layouts: {
                    lg: [
                      ...(state.data.tabs?.[state.selectedTabIndex]?.layouts?.lg || []),
                      { x: 0, y: lgY, h: 16, w: 16, i: workspacePayload.layerId },
                    ],
                    md: [
                      ...(state.data.tabs?.[state.selectedTabIndex]?.layouts?.md || []),
                      { x: 0, y: mdY, h: 16, w: 8, i: workspacePayload.layerId },
                    ],
                    sm: [
                      ...(state.data.tabs?.[state.selectedTabIndex]?.layouts?.sm || []),
                      { x: 0, y: smY, h: 16, w: 4, i: workspacePayload.layerId },
                    ],
                  },
                },
              },
            },
          };
        }
      } else {
        // this is adding a child report report to an existing workspace
        // (you cannot add a child report to a brand new workspace)

        // if we add child report to a new tab, we need to calculate next tab id, otherwise, use current tab id
        if (toNewTab) {
          const newTabId = maxTabId(state.data.tabs) + 1;
          return {
            ...state,
            selectedTabIndex: newTabId,
            data: {
              ...state.data,
              tabs: {
                ...state.data.tabs,
                [newTabId]: {
                  name: 'Drill-Through',
                  reports: {
                    [workspacePayload.sequenceId]: workspacePayload,
                  },
                  layouts: {
                    lg: [{ x: 0, y: 0, h: 16, w: 16, i: workspacePayload.layerId }],
                    md: [{ x: 0, y: 0, h: 16, w: 8, i: workspacePayload.layerId }],
                    sm: [{ x: 0, y: 0, h: 16, w: 4, i: workspacePayload.layerId }],
                  },
                },
              },
            },
          };
        } else {
          const { lgY, mdY, smY } = findWorkspaceBottom(state.data.tabs[state.selectedTabIndex]);
          return {
            ...state,
            data: {
              ...state.data,
              tabs: {
                ...state.data.tabs,
                [state.selectedTabIndex]: {
                  ...state.data.tabs[state.selectedTabIndex],
                  reports: {
                    ...state.data.tabs[state.selectedTabIndex].reports,
                    [workspacePayload.sequenceId]: workspacePayload,
                  },
                  layouts: {
                    lg: [
                      ...(state.data.tabs?.[state.selectedTabIndex]?.layouts?.lg || []),
                      { x: 0, y: lgY, h: 16, w: 16, i: workspacePayload.layerId },
                    ],
                    md: [
                      ...(state.data.tabs?.[state.selectedTabIndex]?.layouts?.md || []),
                      { x: 0, y: mdY, h: 16, w: 8, i: workspacePayload.layerId },
                    ],
                    sm: [
                      ...(state.data.tabs?.[state.selectedTabIndex]?.layouts?.sm || []),
                      { x: 0, y: smY, h: 16, w: 4, i: workspacePayload.layerId },
                    ],
                  },
                },
              },
            },
          };
        }
      }
    }
    case ActionTypes.CLEAR_WORKSPACE: {
      return {
        ...state,
        data: defaultWorkspace,
        selectedTabIndex: 1,
        maximizedLayerId: null,
        isLoading: false,
        errMessage: null,
        parentToChildRelationship: {},
      };
    }
    case ActionTypes.UPDATE_WORKSPACE_LAYOUT: {
      const {
        tabId,
        layouts,
      }: { tabId: number; layouts: ReactGridLayout.Layouts } = action.payload;

      // destructure the tab which contains the layouts to update
      const { [tabId]: tab, ...remainingTabs } = state.data.tabs;

      return {
        ...state,
        data: { ...state.data, tabs: { ...remainingTabs, [tabId]: { ...tab, layouts } } },
      };
    }
    case ActionTypes.TOGGLE_REPORT_LIVE_STATUS: {
      const { sequenceId, live, parentSelectedElements } = action.payload;
      const tabId = findTabId(sequenceId, state.data);

      return {
        ...state,
        data: {
          ...state.data,
          tabs: {
            ...state.data.tabs,
            [tabId]: {
              ...state.data.tabs[tabId],
              reports: {
                ...state.data.tabs[tabId].reports,
                [sequenceId]: {
                  ...state.data.tabs[tabId].reports[sequenceId],
                  live,
                  parentSelectedElements: live ? null : parentSelectedElements,
                },
              },
            },
          },
        },
      };
    }

    case ActionTypes.CHANGE_SELECTED_TAB: {
      return {
        ...state,
        maximizedLayerId: null,
        selectedTabIndex: action.payload.tabIndex,
      };
    }
    case ActionTypes.SET_SELECTED_REPORT_IMPORT_FILE: {
      return { ...state, selectedReportImportFile: action.payload };
    }
    case ActionTypes.SET_SELECTED_WORKSPACE_IMPORT_FILE: {
      return { ...state, selectedWorkspaceImportFile: action.payload };
    }
    case ActionTypes.SET_MAXIMIZED_LAYER_ID: {
      return { ...state, maximizedLayerId: action.payload };
    }
    case ActionTypes.SET_WORKSPACE_PATH_AND_ID: {
      const { path, id } = action.payload;
      return { ...state, data: { ...state.data, path, id } };
    }
    case ActionTypes.SET_SCROLL_PENDING: {
      return { ...state, scrollPending: action.payload };
    }
    case ActionTypes.SET_WORKSPACE_PORTFOLIO_SELECTION: {
      // action.payload is a SelectedPortfolios
      return { ...state, data: { ...state.data, ...action.payload } };
    }
    case ActionTypes.SET_SELECTED_WORKSPACE_ENTITIES: {
      // action.payload is a SelectedEntities
      return { ...state, data: { ...state.data, selectedEntities: action.payload } };
    }
    case ActionTypes.IMPORT_WORKSPACE: {
      return {
        ...state,
        maximizedLayerId: null,
        ...action.payload,
      };
    }
    case ActionTypes.SET_WORKSPACE_SANDBOX: {
      const { tabId, sequenceId, sandbox } = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          tabs: {
            ...state.data.tabs,
            [tabId]: {
              ...state.data.tabs[tabId],
              reports: {
                ...state.data.tabs[tabId].reports,
                [sequenceId]: {
                  ...state.data.tabs[tabId].reports[sequenceId],
                  sandbox,
                },
              },
            },
          },
        },
      };
    }

    case ActionTypes.SET_ACTIVE_PAGE: {
      return {
        ...state,
        maximizedLayerId: null,
      };
    }

    case ActionTypes.SET_WORKSPACE_FILTER: {
      return { ...state, data: { ...state.data, filter: action.payload } };
    }

    default:
      return state;
  }
};
