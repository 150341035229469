import { DrawerState } from '../shared/dataTypes';
import { closeReportEntitiesDrawer, openReportEntitiesDrawer } from './ActionCreators';
import { CLOSE_REPORT_ENTITIES_DRAWER, OPEN_REPORT_ENTITIES_DRAWER } from './ActionTypes';

type Action =
  | ReturnType<typeof openReportEntitiesDrawer>
  | ReturnType<typeof closeReportEntitiesDrawer>;

export const reportEntitiesDrawer = (
  state: DrawerState = { open: false, sequenceId: undefined },
  action: Action,
): DrawerState => {
  switch (action.type) {
    case OPEN_REPORT_ENTITIES_DRAWER:
      return { open: true, sequenceId: action.payload };
    case CLOSE_REPORT_ENTITIES_DRAWER:
      return { open: false, sequenceId: undefined };
    default:
      return state;
  }
};
