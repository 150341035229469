import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProxyConfigDto } from '../components/drawers/proxy-drawer/utils';
import { ALL_CHECKBOX_SELECTION_ID } from '../shared/constants';
import {
  Characteristic,
  FilterType,
  OptionTypes,
  ReportDefinitionFilter,
} from '../shared/dataTypes';

export type ReportFilterDrawerState = {
  open: boolean;
  sequenceId: number | undefined;
  positionToEdit?: string;
  initialPosition?: ProxyConfigDto;
  filters?: ReportDefinitionFilter[];
};

const initialState: ReportFilterDrawerState = { open: false, sequenceId: undefined };

const reportFilterDrawerSlice = createSlice({
  name: 'reportFilterDrawer',
  initialState,
  reducers: {
    reportFilterDrawerOpened: (
      _state,
      action: PayloadAction<{ sequenceId: number; filters: ReportDefinitionFilter[] }>,
    ) => ({ open: true, sequenceId: action.payload.sequenceId, filters: action.payload.filters }),
    reportFilterDrawerClosed: () => ({ open: false, sequenceId: undefined }),
    defaultReportFilterAdded: (state, action: PayloadAction<Characteristic>) => {
      const defaultFilter: ReportDefinitionFilter = {
        charId: action.payload.charId,
        type: 'EQUALS',
      };
      if (state.filters) {
        state.filters.push(defaultFilter);
      } else {
        state.filters = [defaultFilter];
      }
    },
    reportFilterRemoved: (state, action: PayloadAction<number>) => {
      state.filters = state.filters?.filter(filter => filter.charId !== action.payload);
    },
    reportFilterTypeUpdated: (
      state,
      action: PayloadAction<{ charId: number; filterType: FilterType }>,
    ) => {
      const filter = state.filters?.find(filter => filter.charId === action.payload.charId);

      if (filter) {
        filter.type = action.payload.filterType;
        filter.selectedOptions = undefined;
        filter.null = action.payload.filterType === 'LIST';
      }
    },
    reportFilterSetNot: (state, action: PayloadAction<{ charId: number; value: boolean }>) => {
      const filter = state.filters?.find(filter => filter.charId === action.payload.charId);
      if (filter) {
        filter.not = action.payload.value;
      }
    },
    reportFilterSetNull: (state, action: PayloadAction<{ charId: number; value: boolean }>) => {
      const filter = state.filters?.find(filter => filter.charId === action.payload.charId);
      if (filter) {
        filter.null = action.payload.value;
      }
    },
    setSelectedReportFilterOptions: (
      state,
      action: PayloadAction<{ charId: number; options: OptionTypes[] }>,
    ) => {
      const filter = state.filters?.find(filter => filter.charId === action.payload.charId);
      if (filter) {
        filter.selectedOptions = action.payload.options.filter(
          (o: OptionTypes) => o !== ALL_CHECKBOX_SELECTION_ID,
        );
      }
    },
  },
});

export const {
  reportFilterDrawerOpened,
  reportFilterDrawerClosed,
  defaultReportFilterAdded,
  reportFilterRemoved,
  reportFilterTypeUpdated,
  reportFilterSetNot,
  reportFilterSetNull,
  setSelectedReportFilterOptions,
} = reportFilterDrawerSlice.actions;

export default reportFilterDrawerSlice.reducer;
