import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../configureStore';
import { ProgressData, ProgressState } from './state';

export const updateProgressFromServer = (progress: ProgressData[]): AppThunk => dispatch => {
  if (progress[0].end) {
    dispatch(progressEnded(progress[0].sequenceId));
  } else {
    dispatch(progressUpdated({ progress, sequenceId: progress[0].sequenceId }));
  }
};

const slice = createSlice({
  name: 'progress',
  initialState: {} as ProgressState,
  reducers: {
    progressUpdated: (
      state,
      action: PayloadAction<{ sequenceId: number; progress: ProgressData[] }>,
    ) => {
      const { sequenceId, progress } = action.payload;
      state[sequenceId] = progress;
    },

    progressEnded: (state, action: PayloadAction<number>) => {
      delete state[action.payload];
    },

    sequenceIdChanged: (
      state,
      action: PayloadAction<{ oldSequenceId: number; sequenceId: number }>,
    ) => {
      state[action.payload.sequenceId] = state[action.payload.oldSequenceId];
      delete state[action.payload.oldSequenceId];
    },
  },
});

export const { progressUpdated, progressEnded, sequenceIdChanged } = slice.actions;
export default slice.reducer;
