import { editor } from 'monaco-editor';
import aScriptRules from '../ascript/themeRules';
import foliaRules from '../folia/themeRules';
import colors from './colors';

/**
 * To minimize complexity, we use a single monaco syntax
 * highlighting theme for all languages. This eliminates the
 * need to switch themes on mount/unmount. There's no risk
 * of collision, as all tokens are prefixed with language ID.
 */
export const THEME_ID = 'aggregate-theme';
const theme: editor.IStandaloneThemeData = {
  base: 'vs',
  inherit: true,
  colors: {
    'editor.background': colors.white,
    'editor.foreground': colors.black,
  },
  rules: [...aScriptRules, ...foliaRules],
};

export default theme;
