export interface SimpleToken {
  type: string;
  text: string | null;
}

export const createEOLToken = (): SimpleToken => ({
  type: 'EOL',
  text: null,
});

export const createEOFToken = (): SimpleToken => ({
  type: 'EOF',
  text: null,
});
