import {
  convertDxSeriesData,
  DxSeriesChart,
  DxSeriesChartType,
  SeriesChartData,
  useUuid,
} from 'algo-react-dataviz';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { updateElementSelection } from '../redux/ActionCreators';
import { VisualizationComponent } from './visualizationComponents';

const createDxSeriesChartViz = (chartType: DxSeriesChartType): VisualizationComponent => props => {
  const dispatch = useDispatch();
  const id = useUuid();

  const { sequenceId, reportRawData } = props;

  const data = useMemo<SeriesChartData | null>(
    () => (!reportRawData ? null : convertDxSeriesData(reportRawData)),
    [reportRawData],
  );

  const onSelectedIdsChange = useCallback(
    (selectedIds: string[]) => {
      dispatch(updateElementSelection(sequenceId, selectedIds));
    },
    [dispatch, sequenceId],
  );

  return !data ? null : (
    <DxSeriesChart
      {...{
        id,
        chartType,
        data,
        onSelectedIdsChange,
      }}
      transpose={props.isInverted}
      selectionMode='modifierDependent'
      selectedIds={props.selectedElements ?? []}
    />
  );
};

export default createDxSeriesChartViz;
