import { EditorLanguage } from '../editorLanguage';
import Token from '../Token';
import TokensProvider from '../TokensProvider';
import FoliaState from './FoliaState';

class FoliaTokensProvider extends TokensProvider<EditorLanguage.FOLIA, FoliaState> {
  constructor(tokenizeLine: (input: string, state: FoliaState) => Token<EditorLanguage.FOLIA>[]) {
    super(FoliaState, tokenizeLine);
  }
}

export default FoliaTokensProvider;
