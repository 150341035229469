// @ts-strict-ignore
import { EditorLanguage } from '../editorLanguage';
import Token from '../Token';
import TokensProvider from '../TokensProvider';
import AScriptState from './AScriptState';

class AScriptTokensProvider extends TokensProvider<EditorLanguage.ASCRIPT, AScriptState> {
  constructor(
    tokenizeLine: (input: string, state: AScriptState) => Token<EditorLanguage.ASCRIPT>[],
  ) {
    super(AScriptState, tokenizeLine);
  }
}

export default AScriptTokensProvider;
