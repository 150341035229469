import { FC } from 'react';
import useAuth from '../auth/useAuth';
import LoadingStub from './LoadingStub';

interface Props {
  url: string;
}

const Callback: FC<Props> = props => {
  useAuth().signinRedirectCallback(props.url);

  return <LoadingStub />;
};

export default Callback;
