// @ts-strict-ignore
import { AppState } from '../../redux/configureStore';
import { PortfolioNode, SelectedPortfolios, WorkspaceData } from '../../shared/dataTypes';

export const PORTFOLIO_HIERARCHY_ROOT_ID = 'rootFolderId';
export const AD_HOC_PORTFOLIOS_ROOT_ID = 'root';

export const hasSelectedPortfolios = ({
  selectedPortfolioNodeIds,
  selectedAdHocPortfolioNames,
  selectedBenchmarkPortfolioNames,
  selectedPortfolioHierarchyName,
}: SelectedPortfolios = {}) =>
  !!selectedPortfolioHierarchyName ||
  selectedPortfolioNodeIds?.length >= 1 ||
  selectedAdHocPortfolioNames?.length >= 1 ||
  selectedBenchmarkPortfolioNames?.length >= 1;

export const countSelectedPortfolios = ({
  selectedPortfolioNodeIds,
  selectedAdHocPortfolioNames,
  selectedBenchmarkPortfolioNames,
}: SelectedPortfolios = {}) =>
  (selectedPortfolioNodeIds?.length ?? 0) +
  (selectedAdHocPortfolioNames?.length ?? 0) +
  (selectedBenchmarkPortfolioNames?.length ?? 0);

/**
 * Portfolio selection precedence
 * 1) Report-scope selection (skipped if `sequenceId` is `null`)
 * 2) Workspace-scope selection
 * 3) Active session selection (set from home page)
 * 4) User default selection
 * 5) None (all values `undefined`)
 */
export const getSelectedPortfolios = (
  state: AppState,
  sequenceId?: number,
  workspace?: WorkspaceData,
) => {
  const reportPortfolioSelection = sequenceId && state.report.reportDefinition[sequenceId];
  if (hasSelectedPortfolios(reportPortfolioSelection || {})) {
    const {
      selectedPortfolioHierarchyName,
      selectedPortfolioNodeIds,
      selectedAdHocPortfolioNames,
      selectedBenchmarkPortfolioNames,
    } = reportPortfolioSelection;

    return {
      selectedPortfolioHierarchyName,
      selectedPortfolioNodeIds,
      selectedAdHocPortfolioNames,
      selectedBenchmarkPortfolioNames,
    };
  }

  const workspacePortfolioSelection = workspace ?? state.workspace.data;
  if (hasSelectedPortfolios(workspacePortfolioSelection ?? {})) {
    const {
      selectedPortfolioHierarchyName,
      selectedPortfolioNodeIds,
      selectedAdHocPortfolioNames,
      selectedBenchmarkPortfolioNames,
    } = workspacePortfolioSelection;

    return {
      selectedPortfolioHierarchyName,
      selectedPortfolioNodeIds,
      selectedAdHocPortfolioNames,
      selectedBenchmarkPortfolioNames,
    };
  }

  const activeSessionPortfolioSelection =
    state.user.userInfo.userPreferences.activePortfolioSelection ?? {};
  if (hasSelectedPortfolios(activeSessionPortfolioSelection)) {
    return activeSessionPortfolioSelection;
  }

  return state.user.userInfo.userPreferences.defaultPortfolioSelection ?? {};
};

export const processPortfolioTreeSelection = (
  selection: string[],
  portfolioHierarchy: PortfolioNode<string>,
  includeNewNodes = true,
): string[] => {
  if (!selection?.length) {
    return [];
  }
  const treeSelection = new Set(selection);

  // build the new tree selection
  // root node is special, so we skip it
  portfolioHierarchy.children?.forEach(node =>
    // buildNewTreeSelection will mutate treeSelection set in place
    buildNewTreeSelection(treeSelection, node, includeNewNodes),
  );

  return Array.from(treeSelection);
};

// this method mutates treeSelection set to roll up or down node selection based on includeNewNodes
const buildNewTreeSelection = (
  treeSelection: Set<string>,
  node: PortfolioNode<string>,
  includeNewNodes: boolean,
): boolean | void => {
  const fqName = node.id;
  if (includeNewNodes) {
    // switching to include new portfolios
    // roll up all children selected to just parent selected
    if (!node.children) {
      // this is a leaf. simply return if it is currently selected or not (so we can compute it's parent state)
      return treeSelection.has(fqName);
    } else {
      const allChildrenSelected = node.children
        .map(child => buildNewTreeSelection(treeSelection, child, includeNewNodes))
        .every(isSelected => isSelected);
      if (allChildrenSelected) {
        // unselect children, select this node and return true
        node.children.forEach(child => treeSelection.delete(child.id));
        treeSelection.add(fqName);
        return true;
      } else {
        // unselect this node and return false
        treeSelection.delete(fqName);
        return false;
      }
    }
  } else {
    // switching to NOT include new portfolios
    // roll down selected parents to just children selected
    if (treeSelection.has(fqName) && node.children) {
      // this node is a selected parent, unselect it and select its children
      treeSelection.delete(fqName);
      node.children.forEach(child => treeSelection.add(child.id));
    }

    if (node.children) {
      node.children.forEach(child => buildNewTreeSelection(treeSelection, child, includeNewNodes));
    }
  }
};
