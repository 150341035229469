import { languages } from 'monaco-editor';
import { EditorLanguage } from './editorLanguage';

abstract class State<LanguageId extends EditorLanguage> implements languages.IState {
  constructor(public readonly languageId: LanguageId) {}

  abstract clone: () => State<LanguageId>;
  equals = (other: this) => this.languageId === other.languageId;
}

export default State;
