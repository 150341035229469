import { EditorLanguage } from '../editorLanguage';
import TokenFactory from '../TokenFactory';
import { AScriptParser } from './antlr/AScriptParser';

class AScriptTokenFactory extends TokenFactory<EditorLanguage.ASCRIPT> {
  constructor() {
    super(EditorLanguage.ASCRIPT, AScriptParser.VOCABULARY);
  }
}

export default AScriptTokenFactory;
