import { Reducer } from '@reduxjs/toolkit';
import { Performance } from '../../../model/performance';
import { ScenariosConfig } from '../../../model/scenariosConfig';
import { Settings } from '../../../model/settings';
import { DESIGNER_SEQUENCE_ID } from '../../../shared/constants';
import { Benchmark } from '../../../shared/dataTypes';
import {
  setAllChars,
  setCharToNickname,
  setHasGenerated,
  setIsAutoGenerate,
  setOriginalDetachedReport,
  setShouldRegenerate,
} from '../../ActionCreators';
import {
  DELETE_REPORT_DATA,
  INITIALIZE_DESIGNER,
  REMOVE_REPORT_PORTFOLIO_NODES,
  RESET_DESIGNER,
  SET_ALL_CHARS,
  SET_CHAR_TO_NICKNAME,
  SET_HAS_GENERATED,
  SET_IS_AUTOGENERATE,
  SET_ORIGINAL_DETACHED_REPORT,
  SET_REPORT_PORTFOLIO_NODES,
  SET_SHOULD_REGENERATE,
  UPDATE_REPORT_DEFINITION_BENCHMARKS,
  UPDATE_REPORT_DEFINITION_PERFORMANCE,
  UPDATE_REPORT_DEFINITION_SCENARIOS_CONFIG,
  UPDATE_REPORT_DEFINITION_SETTINGS,
} from '../../ActionTypes';
import { initializeDesigner, resetDesigner } from '../../ReportActionCreators';
import { deleteReportData } from '../../WorkspaceActionCreators';
import { DesignerPanelState, createDefaultState } from './state';

type Action =
  | ReturnType<typeof resetDesigner>
  | ReturnType<typeof initializeDesigner>
  | ReturnType<typeof setShouldRegenerate>
  | ReturnType<typeof setIsAutoGenerate>
  | ReturnType<typeof setAllChars>
  | ReturnType<typeof setCharToNickname>
  | ReturnType<typeof setOriginalDetachedReport>
  | ReturnType<typeof deleteReportData>
  | ReturnType<typeof setHasGenerated>
  | { type: typeof UPDATE_REPORT_DEFINITION_SETTINGS; payload: Settings }
  | { type: typeof UPDATE_REPORT_DEFINITION_SCENARIOS_CONFIG; payload: ScenariosConfig }
  | { type: typeof UPDATE_REPORT_DEFINITION_BENCHMARKS; payload: Benchmark }
  | { type: typeof UPDATE_REPORT_DEFINITION_PERFORMANCE; payload: Performance }
  | { type: typeof REMOVE_REPORT_PORTFOLIO_NODES }
  | { type: typeof SET_REPORT_PORTFOLIO_NODES };

const reducer: Reducer<DesignerPanelState, Action> = (
  state = createDefaultState(),
  action: Action,
) => {
  switch (action.type) {
    case RESET_DESIGNER:
      return {
        ...createDefaultState(),
        isAutoGenerate: state.isAutoGenerate,
      };

    case INITIALIZE_DESIGNER:
      return {
        ...createDefaultState(),
        isAutoGenerate: state.isAutoGenerate,
        ...action.payload,
        originalDetachedReport: state.originalDetachedReport,
      };

    case SET_ORIGINAL_DETACHED_REPORT:
      return { ...state, originalDetachedReport: action.payload };

    case SET_SHOULD_REGENERATE:
      return {
        ...state,
        shouldRegenerate: action.payload,
        hasGenerated: state.hasGenerated || action.payload,
      };

    case SET_HAS_GENERATED:
      return { ...state, hasGenerated: action.payload };

    case SET_IS_AUTOGENERATE:
      return {
        ...state,
        isAutoGenerate: action.payload,
        shouldRegenerate: action.payload,
        hasGenerated: state.hasGenerated || action.payload,
      };

    case SET_ALL_CHARS:
      return { ...state, allChars: action.payload };

    case SET_CHAR_TO_NICKNAME:
      return { ...state, charToNickname: action.payload };

    // Actions primarily for another state slice but which may
    // trigger regeneration, depending on `isAutoGenerate`
    case UPDATE_REPORT_DEFINITION_SCENARIOS_CONFIG:
    case UPDATE_REPORT_DEFINITION_SETTINGS:
    case UPDATE_REPORT_DEFINITION_BENCHMARKS:
    case UPDATE_REPORT_DEFINITION_PERFORMANCE:
    case REMOVE_REPORT_PORTFOLIO_NODES:
    case SET_REPORT_PORTFOLIO_NODES:
      return {
        ...state,
        shouldRegenerate: state.isAutoGenerate,
        hasGenerated: state.hasGenerated || state.isAutoGenerate,
      };

    // Action for another state slice; reset hasGenerated to false when closing designer
    case DELETE_REPORT_DATA:
      return {
        ...state,
        hasGenerated: action.payload === DESIGNER_SEQUENCE_ID ? false : state.hasGenerated,
      };

    default:
      return state;
  }
};

export default reducer;
