import { useEffect, useMemo } from 'react';
import useCompletionProviderService from '../ascript/completion-providers/useCompletionProviderService';
import { EditorLanguage } from '../editorLanguage';
import useMemoizedMonaco from '../memoized-monaco/useMemoizedMonaco';
import FoliaLanguageService from './FoliaLanguageService';

const useFoliaLanguage = () => {
  const monaco = useMemoizedMonaco();
  const languageService = useMemo(() => new FoliaLanguageService(), []);
  const completionProviderService = useCompletionProviderService(EditorLanguage.FOLIA);

  useEffect(() => {
    if (!monaco) {
      return;
    }

    languageService.register(monaco);
  }, [monaco, languageService]);

  useEffect(() => {
    if (!monaco || !completionProviderService) {
      return;
    }

    return () => {
      completionProviderService.clearAllProviders();
    };
  }, [monaco, completionProviderService]);

  return languageService;
};

export default useFoliaLanguage;
