// @ts-strict-ignore
import { CommonToken } from 'antlr4ts';
import { editor, MarkerSeverity } from 'monaco-editor';
import ErrorCollector, { ANTLRErrorParameters } from './ErrorCollector';

export const createMonacoError = <T extends CommonToken>(
  ...[_recognizer, offendingSymbol, line, column, message, _error]: ANTLRErrorParameters<T>
): editor.IMarkerData => ({
  severity: MarkerSeverity.Error,
  message,
  startLineNumber: line,
  endLineNumber: line,
  startColumn: column + 1,
  endColumn: column + 1 + Math.max(1, offendingSymbol.stopIndex - offendingSymbol.startIndex + 1),
});

class MonacoErrorCollector<T extends CommonToken> extends ErrorCollector<T, editor.IMarkerData> {
  constructor() {
    super(createMonacoError);
  }
}

export default MonacoErrorCollector;
