import { FC } from 'react';
import useAuth from '../auth/useAuth';
import LoadingStub from './LoadingStub';

const LogoutCallback: FC = () => {
  useAuth().signoutRedirectCallback();

  return <LoadingStub />;
};

export default LogoutCallback;
