import { FormikProps } from 'formik';
import { EditorInterfaceType } from '../../components/editor/ascript/model/editorInterfaceType';
import { CharacteristicDrawerForm } from '../../components/metadata/characteristic-drawer/characteristicDrawerForm';
import { createDefaultAScriptContents as createDefaultContents } from '../../lang/editorLanguage';

export enum AScriptEditorActionType {
  OPEN = 'editor/open',
  CLOSE = 'editor/close',

  SET_SUPERCLASS_NAME = 'editor/setSuperclassName',
  SET_READ_ONLY = 'editor/setReadOnly',
  SET_CLASS_NAME = 'editor/setClassName',
}

export type AScriptEditorAction =
  | ReturnType<typeof openEditor>
  | ReturnType<typeof closeEditor>
  | ReturnType<typeof setClassName>
  | ReturnType<typeof setSuperclass>;

export const openEditor = (
  interfaceType: EditorInterfaceType,
  className: string,
  superclassName: string | null,
  initialContents = createDefaultContents(),
  formProps: FormikProps<CharacteristicDrawerForm>,
) =>
  ({
    type: AScriptEditorActionType.OPEN,
    payload: {
      interfaceType,
      className,
      superclassName,
      initialContents,
      formProps,
    },
  } as const);

export const closeEditor = () =>
  ({
    type: AScriptEditorActionType.CLOSE,
  } as const);

export const setSuperclass = (superclass: string) =>
  ({
    type: AScriptEditorActionType.SET_SUPERCLASS_NAME,
    payload: superclass,
  } as const);

export const setClassName = (className: string) =>
  ({
    type: AScriptEditorActionType.SET_CLASS_NAME,
    payload: className,
  } as const);
