// @ts-strict-ignore
import { Modifier } from 'algo-react-dataviz';
import axios from 'axios';
import { baseUrl, isNativePasswordEncrypted } from '../components/shared/environment';
import { NotificationLevel } from '../shared/constants';
import { ContextPermissions, UserInfo } from '../shared/dataTypes';
import { clientUuid } from '../shared/utils';
import {
  enqueueSnackbar,
  fetchCurrencies,
  fetchDateContexts,
  getErrorMessage,
} from './ActionCreators';
import * as ActionTypes from './ActionTypes';
import { AppThunk, connectWS } from './configureStore';
import { wsConnected } from './reportSaga';
import { fetchWorkspace } from './WorkspaceActionCreators';
import JSEncrypt from 'jsencrypt';
import { getGroupingListsData } from './grouping-lists/thunks';

export const setupLoggedInUser: (
  token: string,
  username: string,
  oidcLogout?: () => void,
  expiresIn?: () => number,
) => AppThunk = (
  token: string,
  username: string,
  oidcLogout?: () => void,
  expiresIn?: () => number,
): AppThunk => dispatch => {
  dispatch(postSuccessfulLoginActions(token, username, oidcLogout, expiresIn));
};

/**
 * @param dataString the string to be encrypted
 * @returns the encrypted string if successful | false if encryption failed | null if encryption is not supported
 */
export const encryptString = async (dataString: string): Promise<string | false | null> => {
  const { data } = await axios.get<{ publicKey: string }>(`${baseUrl}api/getPublicKey`);
  const key: string = data.publicKey;
  if (null != key) {
    // JSEncrypt supports RSA public/private key encryption.  Crypto-js does not support this.
    const jsencrypt: JSEncrypt = new JSEncrypt();
    jsencrypt.setPublicKey(key);
    const encB64: string | false = jsencrypt.encrypt(dataString);
    return encB64;
  } else {
    return null;
  }
};

export const loginNative: (username: string, password: string) => AppThunk = (
  username: string,
  password: string,
): AppThunk => async dispatch => {
  if (isNativePasswordEncrypted) {
    const encPass = await encryptString(password);
    if (null == encPass) {
      password = btoa(password);
    } else if (encPass) {
      password = encPass;
    } else {
      dispatch(enqueueSnackbar(NotificationLevel.ERROR, 'Login invalid'));
      return;
    }
  }
  axios
    .post(`${baseUrl}authenticate`, { username, password, clientUuid })
    .then(response => response.data)
    .then(data => {
      if (data.token) {
        dispatch(postSuccessfulLoginActions(data.token, username));
      } else {
        throw new Error('No token received');
      }
    })
    .catch((error: Error) => {
      dispatch(
        enqueueSnackbar(
          NotificationLevel.ERROR,
          error.message === 'Network Error'
            ? 'Login failed, cannot reach server'
            : 'Login failed, please try again',
        ),
      );
      dispatch(failedLogin());
    });
};

export const loginPsbc: (psbcToken: string) => AppThunk = (psbcToken: string): AppThunk => async (
  dispatch,
  getState,
) => {
  axios
    .post(`${baseUrl}authenticate`, { token: psbcToken, clientUuid })
    .then(response => response.data)
    .then(data => {
      if (data.token) {
        dispatch(postSuccessfulLoginActions(data.token, data.sub, null, null, data.exp * 1000)); //getExpMethod(data.exp)));
        const refreshTimeout = 1000 * Math.max(5, (data.exp - Date.now() / 1000) / 2);
        const refreshTimeoutId = window.setTimeout(() => dispatch(refreshPsbc()), refreshTimeout);
        dispatch(setRefreshTimeoutId(refreshTimeoutId));
      } else {
        throw new Error('PSBC No token received');
      }
    })
    .catch((error: Error) => {
      dispatch(
        enqueueSnackbar(
          NotificationLevel.ERROR,
          error.message === 'Network Error'
            ? 'PSBC Login failed, cannot reach server'
            : 'PSBC Login failed, please try again',
        ),
      );
      dispatch(failedLogin());
    });
};

const setRefreshTimeoutId = (refreshTimeoutId: number) => ({
  type: ActionTypes.SET_REFRESH_TIMEOUT_ID,
  payload: { refreshTimeoutId: refreshTimeoutId },
});
const clearRefreshTimeoutId = () => ({
  type: ActionTypes.CLEAR_REFRESH_TIMEOUT_ID,
  payload: {},
});
export const cancelRefreshTimeout = (timerId: number | null) => {
  if (timerId !== null) {
    window.clearTimeout(timerId);
  }
};

export const refreshPsbc: () => AppThunk = (): AppThunk => async (dispatch, getState) => {
  const token = getState().user.tk;
  const loggedIn = getState().user.loggedIn;
  const prevTimerId = getState().user.refreshTimeoutId;
  if (null != prevTimerId) {
    window.clearTimeout(prevTimerId);
    delete axios.defaults.headers.common['authorization'];
    dispatch(clearRefreshTimeoutId());
  }
  if (null === token || !loggedIn) {
    return () => {};
  }
  axios
    .post(`${baseUrl}refresh`, { token: token, clientUuid })
    .then(response => response.data)
    .then(data => {
      if (data.token) {
        dispatch(postSuccessfulLoginActions(data.token, data.sub, null, null, data.exp * 1000)); //getExpMethod(data.exp)));
        const refreshTimeout = Math.max(5000, (1000 * data.exp - Date.now()) / 2);
        const refreshTimeoutId = window.setTimeout(() => dispatch(refreshPsbc()), refreshTimeout);
        dispatch(setRefreshTimeoutId(refreshTimeoutId));
      } else {
        const refreshTimeout = 5000;
        const refreshTimeoutId = window.setTimeout(() => dispatch(refreshPsbc()), refreshTimeout);
        dispatch(setRefreshTimeoutId(refreshTimeoutId));
        throw new Error('PSBC No refresh token received');
      }
    })
    .catch((error: Error) => {
      dispatch(
        enqueueSnackbar(
          NotificationLevel.ERROR,
          error.message === 'Network Error'
            ? 'PSBC Login failed, cannot reach server'
            : 'PSBC Login failed, please try again',
        ),
      );
      dispatch(failedLogin());
    });
};

export const restoreLogin = (): AppThunk => () => {
  // we are not going to restore login and force user to login again until at least refresh tokens are implemented
  // const token = localStorage.getItem('token');
  // const username = localStorage.getItem('username');
  // if (token && username) {
  //   dispatchPostSuccessfulLoginActions(dispatch, token, username);
  // }
};

const postSuccessfulLoginActions = (
  token: string,
  username: string,
  oidcLogout?: () => void,
  expiresIn?: () => number,
  expiresAtMs?: number,
): AppThunk => (dispatch, getState) => {
  // sets new token and username in localStorage
  // initiates WS connection

  // Grab this value here because successfulLogin might be about to change it
  const { loggedIn } = getState().user;

  axios.defaults.headers.common['authorization'] = 'Bearer ' + token;

  dispatch(successfulLogin(token, username, expiresIn, expiresAtMs));

  if (!loggedIn) {
    // this will also fetch currencies + contexts, connect the web socket and load default workspace if any
    // but ONLY AFTER successfully retrieving user info since it is the first login
    // it is like this because it is possible that the user is not yet provisioned in the system, so we need to make sure
    // that fetch user info provisions it before we try to retrieve data
    dispatch(fetchUserInfo(true, oidcLogout));

    axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.data) {
          if (error.response.data.error_id) {
            console.error(`${getErrorMessage(error)}: error_id = ${error.response.data.error_id}`);
          }
          if (error.response.data.status === 401) {
            dispatch(sessionExpiredWarningToggle());
          }
        }
        throw error;
      },
    );
  }
};

export const sessionExpiredWarningToggle = () => ({
  type: ActionTypes.USER_SESSION_EXPIRED_WARNING_TOGGLE,
});

export const logout = (): AppThunk => (dispatch, getState) => {
  axios
    .put(`${baseUrl}api/logoutUser`, {
      user: getState().user.username,
    })
    .catch(error => console.error(`Failed to log user logout: ${getErrorMessage(error)}`));

  dispatch({ type: ActionTypes.USER_LOGOUT });
};

const successfulLogin = (
  tk: string,
  username: string,
  expiresIn?: () => number,
  expiresAtMs?: number,
) => ({
  type: ActionTypes.SUCCESSFUL_LOGIN,
  payload: { tk, username, expiresIn, expiresAtMs },
});

export const fetchUserInfo = (
  newLogin?: boolean,
  oidcLogout?: () => void,
  isPortalEnabled?: boolean,
): AppThunk => dispatch => {
  axios
    .get<UserInfo>(`${baseUrl}api/userInfo`)
    .then(data => {
      dispatch(userInfo(data.data));

      if (isPortalEnabled) {
        dispatch(successfulLogin(data.data.username, data.data.username));
      }

      if (newLogin) {
        dispatch(fetchCurrencies());
        dispatch(fetchDateContexts());
        dispatch(getGroupingListsData());

        connectWS();
      }
    })
    .catch((error: Error) => {
      console.error(
        'Error trying to fetch user info. Exiting application ... (user alerted): ',
        error,
      );
      dispatch(
        enqueueSnackbar(
          NotificationLevel.ERROR,
          `Error trying to retrieve user info. Please consult your administrator.`,
        ),
      );
      // we need to log the user out if we failed to query userInfo endpoint
      oidcLogout?.();
      dispatch(logout());
    });
};

const TOTAL_RETRIES = 5;
export const fetchLandingWorkspace = (workspacePath: string): AppThunk => dispatch => {
  console.log(`openLandingWorkspace(workspacePath = ${workspacePath})`);
  if (!workspacePath) {
    return;
  }

  const fetch = (retriesLeft: number) => () => {
    if (wsConnected()) {
      console.log('Fetching workspace...');
      dispatch(fetchWorkspace(workspacePath, null, null));
      return;
    }

    console.log('WebSocket not connected');

    if (retriesLeft > 0) {
      const retryTimeout = (TOTAL_RETRIES - retriesLeft) * 1000;
      console.log(`Retrying after ${retryTimeout} ms`);

      setTimeout(fetch(retriesLeft - 1), retryTimeout);
    } else {
      dispatch(
        enqueueSnackbar(
          NotificationLevel.ERROR,
          `Failed to load default workspace ${workspacePath}. WebSocket still not connected after 2 seconds.`,
        ),
      );
    }
  };

  setTimeout(fetch(TOTAL_RETRIES), 50);
};

const userInfo = (userInfo: UserInfo) => ({
  type: ActionTypes.USER_INFO,
  payload: userInfo,
});

const failedLogin = () => ({
  type: ActionTypes.FAILED_LOGIN,
});

export const updateFavoriteCharacteristic = (
  charId: number,
  modifier: Modifier,
  markAsFavorite: boolean,
): AppThunk => dispatch =>
  axios
    .post(`${baseUrl}api/updateFavoriteCharacteristic`, null, {
      params: { charId, modifier, markAsFavorite },
    })
    .then(() => {
      dispatch({
        type: markAsFavorite ? ActionTypes.ADD_FAVORITE : ActionTypes.REMOVE_FAVORITE,
        payload: { charId, modifier },
      });
    })
    .catch((error: Error) => {
      dispatch(
        enqueueSnackbar(NotificationLevel.ERROR, `Unable to update favorite: ${error.message}`),
      );
    });

export const updateFavoriteCustomGrouping = (
  name: string,
  markAsFavorite: boolean,
): AppThunk => dispatch =>
  axios
    .post(`${baseUrl}api/updateFavoriteCustomGrouping`, {
      customGrouping: name,
      markAsFavorite,
    })
    .then(() => {
      dispatch({
        type: markAsFavorite
          ? ActionTypes.ADD_FAVORITE_CUSTOM_GROUP
          : ActionTypes.REMOVE_FAVORITE_CUSTOM_GROUP,
        payload: name,
      });
    })
    .catch((error: Error) => {
      dispatch(
        enqueueSnackbar(NotificationLevel.ERROR, `Unable to update favorite: ${error.message}`),
      );
    });

export const updateFavoriteGroupingList = (
  id: string,
  markAsFavorite: boolean,
): AppThunk => dispatch =>
  axios
    .post(`${baseUrl}api/updateFavoriteGroupingList`, null, { params: { id, markAsFavorite } })
    .then(() =>
      dispatch({
        type: markAsFavorite
          ? ActionTypes.ADD_FAVORITE_GROUPING_LIST
          : ActionTypes.REMOVE_FAVORITE_GROUPING_LIST,
        payload: id,
      }),
    )
    .catch((error: Error) => {
      dispatch(
        enqueueSnackbar(NotificationLevel.ERROR, `Unable to update favorite: ${error.message}`),
      );
    });

export const setUserManageContextsPermissions = (payload: ContextPermissions) => ({
  type: ActionTypes.SET_MANAGE_CONTEXTS_PERMISSIONS,
  payload,
});

export const fetchManageContextsPermissions = (): AppThunk => dispatch => {
  axios
    .get(`${baseUrl}api/contextPermissions`)
    .then(response => {
      dispatch(setUserManageContextsPermissions(response.data));
    })
    .catch(error => {
      enqueueSnackbar(
        NotificationLevel.ERROR,
        `Failed to fetch contexts permissions: ${error.message}`,
      );
    });
};

export const pushRecentWorkspace = (workspaceName: string) =>
  ({
    type: ActionTypes.PUSH_RECENT_WORKSPACE,
    payload: workspaceName,
  } as const);

export const removeRecentWorkspace = (workspaceName: string) =>
  ({
    type: ActionTypes.REMOVE_RECENT_WORKSPACE,
    payload: workspaceName,
  } as const);

export const clearRecentWorkspaces = () =>
  ({
    type: ActionTypes.CLEAR_RECENT_WORKSPACES,
  } as const);
