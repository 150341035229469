import { ANTLRErrorListener } from 'antlr4ts';
import { ANTLRErrorParameters } from './ErrorCollector';

export type MessageCreator = <T>(
  ...[_recognizer, _offendingSymbol, _line, _column, message, _error]: ANTLRErrorParameters<T>
) => string;

class ErrorLogger<T> implements ANTLRErrorListener<T> {
  private static wrapTemplate = (template: (message: string) => string): MessageCreator => (
    ...[_recognizer, _offendingSymbol, _line, _column, message, _error]
  ) => template(message);

  private static createDefaultMessage = ErrorLogger.wrapTemplate(message => message);

  static fromTemplate = (template: (message: string) => string) =>
    new ErrorLogger(ErrorLogger.wrapTemplate(template));

  constructor(private createMessage: MessageCreator = ErrorLogger.createDefaultMessage) {}

  syntaxError(...params: ANTLRErrorParameters<T>) {
    console.log(this.createMessage(...params));
  }
}

export default ErrorLogger;
