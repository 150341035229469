// @ts-strict-ignore
import { FC, useEffect, useState } from 'react';
import { Cell, Legend, Pie, PieChart, Tooltip } from 'recharts';
import { paletteP50 as colors } from '../shared/colorPalettes';
import { legendWrapperStyles } from '../shared/constants';
import { DataConverters } from '../shared/dataConverters';
import { ReportConvertedData, ReportProps } from '../shared/dataTypes';
import { useChartOpacity } from './helpers/chartingHelpers';

const TwoLevelPieChartViz: FC<ReportProps> = props => {
  const [opacity, , onChartEnter, onChartLeave] = useChartOpacity({});
  const [reportConvertedData, setReportConvertedData] = useState<ReportConvertedData>();

  useEffect(() => setReportConvertedData(DataConverters.TWO_LEVEL_PIE_CHART(props.reportRawData)), [
    props.reportRawData,
  ]);

  if (props.width <= 0 || props.height <= 0 || !reportConvertedData) {
    return null;
  }

  const series = reportConvertedData.headers;

  const oR = (props.height < props.width * 0.8 ? props.height : props.width * 0.8) * 0.4;

  const smOR = ((oR - 10) * 5) / 6;

  const cx = props.width / 3 + 90;
  const cy = props.height / 2 - 20;

  const pieChartData = reportConvertedData.rows;

  let kind = props.reportRawData.extras.kind;
  if (!kind) {
    kind = reportConvertedData.groupingHeader.name;
  }

  return (
    <PieChart width={props.width} height={props.height}>
      <Tooltip
        formatter={(value, name, props) =>
          `${props.dataKey}: ${props.payload[`${props.dataKey}Formatted`]}`
        }
      />
      {[0, 1].map(i => (
        <Pie
          key={series[i]}
          dataKey={series[i]}
          nameKey={kind}
          data={pieChartData}
          cx={cx}
          cy={cy}
          innerRadius={i === 1 ? smOR + 10 : undefined}
          outerRadius={i === 0 ? smOR : oR}
        >
          {pieChartData.map((entry: any, index: any) => (
            <Cell
              key={entry}
              onClick={props.onElementClick(pieChartData[index][`${series[i]}rowcol`])}
              fill={colors[index % colors.length]}
              fillOpacity={opacity[entry.Grouping] || 1}
            />
          ))}
        </Pie>
      ))}
      <Legend
        layout='vertical'
        align='right'
        verticalAlign='top'
        wrapperStyle={legendWrapperStyles.vertical}
        onMouseEnter={onChartEnter(reportConvertedData.rows.map(row => row.Grouping))}
        onMouseLeave={onChartLeave}
      />
    </PieChart>
  );
};

export default TwoLevelPieChartViz;
