// @ts-strict-ignore
import { Logger } from 'oidc-client-ts';
import { FC } from 'react';
import { Route } from 'react-router-dom';
import useAuth from '../auth/useAuth';
import LoadingStub from './LoadingStub';

const PrivateRoute = ({ component, ...rest }) => {
  const { isAuthenticated, signinRedirect, oidcLogout } = useAuth();

  const renderFn = (Component: FC) => (props: any) => {
    Logger.debug(`PrivateRoute : isAuthenticated=${isAuthenticated()} : Component=${Component}`);

    if (Component && isAuthenticated()) {
      return <Component {...props} {...{ oidcLogout }} />;
    } else {
      signinRedirect(`${props.location.pathname}${props.location.search}`);
      return <LoadingStub />;
    }
  };

  return <Route {...rest} render={renderFn(component)} />;
};

export default PrivateRoute;
