import { FC } from 'react';
import { Route } from 'react-router-dom';
import MainComponent from '../../main/Main';
import { logout } from '../../../redux/UserProfileActionCreators';
import { psbcLogoutRedirect } from '../../shared/environment';

const psbcLogout = () => {
  logout();
  if (psbcLogoutRedirect) {
    window.location.href = psbcLogoutRedirect;
  }
};

const PsbcRoutes: FC = () => (
  <Route render={() => <MainComponent oidcLogout={() => {}} psbcLogout={psbcLogout} />} />
);

export default PsbcRoutes;
