import { DrawerState } from '../shared/dataTypes';
import { closeBenchmarkPortfolioDrawer, openBenchmarkPortfolioDrawer } from './ActionCreators';
import { CLOSE_BENCHMARK_PORTFOLIO_DRAWER, OPEN_BENCHMARK_PORTFOLIO_DRAWER } from './ActionTypes';

type Action =
  | ReturnType<typeof openBenchmarkPortfolioDrawer>
  | ReturnType<typeof closeBenchmarkPortfolioDrawer>;

export const benchmarkPortfolioDrawer = (
  state: DrawerState = { open: false, sequenceId: undefined },
  action: Action,
): DrawerState => {
  switch (action.type) {
    case OPEN_BENCHMARK_PORTFOLIO_DRAWER:
      return { open: true, sequenceId: action.payload };
    case CLOSE_BENCHMARK_PORTFOLIO_DRAWER:
      return { open: false, sequenceId: undefined };
    default:
      return state;
  }
};
