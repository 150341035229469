import { FC } from 'react';
import useAuth from '../auth/useAuth';
import LoadingStub from './LoadingStub';

const SilentRenew: FC = () => {
  useAuth().signinSilentCallback();

  return <LoadingStub />;
};

export default SilentRenew;
