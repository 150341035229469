import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 } from 'uuid';
import { Characteristic } from '../../shared/dataTypes';
import { DataSettingsTab } from '../../components/data-settings/dataSettingsTabs';

export type GroupingList = { id: string; name: string; chars: Characteristic[] };

type State = {
  tab: DataSettingsTab;
  availableCharacteristics: Characteristic[];
  savedGroupingLists: GroupingList[];
  unsavedGroupingLists: GroupingList[];
};

const initialState: State = {
  tab: DataSettingsTab.METADATA_VIEWER,
  availableCharacteristics: [],
  savedGroupingLists: [],
  unsavedGroupingLists: [],
};

const slice = createSlice({
  name: `groupingLists`,
  initialState,
  reducers: {
    setDataSettingsTab: (state, action: PayloadAction<DataSettingsTab>) => {
      state.tab = action.payload;
    },

    stateReset: state => {
      state.unsavedGroupingLists = [...state.savedGroupingLists];
    },

    clearGroupingLists: state => ({ ...initialState, tab: state.tab }),

    availableCharacteristicsPopulated: (state, action: PayloadAction<Characteristic[]>) => {
      state.availableCharacteristics = action.payload;
    },

    listsPopulated: (state, action: PayloadAction<GroupingList[]>) => {
      state.savedGroupingLists = action.payload;
      state.unsavedGroupingLists = action.payload;
    },

    listAdded: state => {
      state.unsavedGroupingLists.push(createEmptyList(getNextName(state.unsavedGroupingLists)));
    },

    listDeleted: (state, action: PayloadAction<{ listId: string }>) => {
      state.unsavedGroupingLists.splice(getListIndexById(state, action.payload.listId), 1);
    },

    itemAdded: (
      state,
      action: PayloadAction<{ listId: string; itemIndex: number; char: Characteristic }>,
    ) => {
      const list = getListById(state, action.payload.listId);

      list?.chars.splice(action.payload.itemIndex, 0, {
        ...action.payload.char,

        // IMPORTANT: When copied from available chars to a different droppable, a draggable
        // must be given a new draggableId, or you will get buggy behavior and this warning:
        // "Detected non-consecutive <Draggable /> indexes."
        draggableId: v4(),
      });
    },

    itemRemoved: (state, action: PayloadAction<{ listId: string; itemIndex: number }>) => {
      const chars = getListById(state, action.payload.listId)?.chars;
      chars?.splice(action.payload.itemIndex, 1);
    },

    itemMoved: (
      state,
      action: PayloadAction<{ listId: string; fromIndex: number; toIndex: number }>,
    ) => {
      const chars = getListById(state, action.payload.listId)?.chars;

      if (chars) {
        const mover = chars[action.payload.fromIndex];
        chars.splice(action.payload.fromIndex, 1);
        chars.splice(action.payload.toIndex, 0, mover);
      }
    },

    listRenamed: (state, action: PayloadAction<{ listId: string; newName: string }>) => {
      const list = getListById(state, action.payload.listId);
      if (list) list.name = action.payload.newName;
    },

    grouperDataSet: (
      state,
      action: PayloadAction<{ listId: string; draggableId: string; grouperData?: string[] }>,
    ) => {
      const list = getListById(state, action.payload.listId);

      if (list) {
        const char = list.chars.find(c => c.draggableId === action.payload.draggableId);

        if (char) {
          char.grouperData = action.payload.grouperData;
        }
      }
    },

    breakpointsSet: (
      state,
      action: PayloadAction<{ listId: string; charId: number; breakpoints?: string[] }>,
    ) => {
      const list = getListById(state, action.payload.listId);

      if (list) {
        const char = list.chars.find(c => c.charId === action.payload.charId);

        if (char) {
          char.breakpoints = action.payload.breakpoints;
        }
      }
    },

    breakpointsRemoved: (state, action: PayloadAction<{ listId: string; charId: number }>) => {
      const list = getListById(state, action.payload.listId);

      if (list) {
        list.chars = list.chars.filter(c => c.charId !== action.payload.charId);
      }
    },

    emptyListsDeleted: state => {
      state.unsavedGroupingLists = state.unsavedGroupingLists.filter(list => list.chars.length > 0);
    },
  },
});

const getNextName = (lists: GroupingList[], i?: number): string => {
  const index = i ?? 1;
  const name = `Grouping List ${index}`;
  return lists.some(list => list.name === name) ? getNextName(lists, index + 1) : name;
};

const createEmptyList = (name: string): GroupingList => ({ id: v4(), name, chars: [] });

const getListById = (state: State, listId: string) =>
  state.unsavedGroupingLists.find(list => list.id === listId);

const getListIndexById = (state: State, listId: string) =>
  state.unsavedGroupingLists.findIndex(list => list.id === listId);

export const {
  setDataSettingsTab,
  stateReset,
  clearGroupingLists,
  availableCharacteristicsPopulated,
  listsPopulated,
  listAdded,
  listDeleted,
  itemAdded,
  itemRemoved,
  itemMoved,
  listRenamed,
  grouperDataSet,
  breakpointsSet,
  breakpointsRemoved,
  emptyListsDeleted,
} = slice.actions;

export default slice.reducer;
