import { languages } from 'monaco-editor';
import { EditorLanguage } from './editorLanguage';
import AbstractState from './State';
import Token from './Token';

class LineTokens<LanguageId extends EditorLanguage, State extends AbstractState<LanguageId>>
  implements languages.ILineTokens {
  constructor(public tokens: Token<LanguageId>[], public endState: State) {}
}

export default LineTokens;
