import { Reducer } from 'redux';
import { createDefaultPerformance, Performance } from '../model/performance';
import { copyPerformance, setPerformance } from './ActionCreators';
import { COPY_PERFORMANCE, SET_PERFORMANCE } from './ActionTypes';

type Action = ReturnType<typeof setPerformance> | ReturnType<typeof copyPerformance>;

const performance: Reducer<Performance, Action> = (state = createDefaultPerformance(), action) => {
  switch (action.type) {
    case COPY_PERFORMANCE: {
      return action.payload;
    }
    case SET_PERFORMANCE: {
      return { ...state, [action.payload.propertyName]: action.payload.propertyValue };
    }
    default: {
      return state;
    }
  }
};

export default performance;
