// @ts-strict-ignore
import { FullDateContextItem } from 'algo-react-dataviz';
import axios from 'axios';
import { baseUrl } from '../components/shared/environment';
import { NotificationLevel } from '../shared/constants';
import {
  AllPermissions,
  ChangeFolderPermission,
  ComponentPermission,
  ComponentPermissions,
  ContextPermissionRule,
  PortfolioPermissions,
  User,
} from '../shared/dataTypes';
import { enqueueSnackbar, getErrorMessage } from './ActionCreators';
import * as ActionTypes from './ActionTypes';
import { AppThunk } from './configureStore';
import { encryptString } from './UserProfileActionCreators';

export const setCreateUserDrawerOpen = (open: boolean) =>
  ({ type: ActionTypes.SET_CREATE_USER_DRAWER_OPEN, payload: open } as const);

export const setCreateGroupDrawerOpen = (open: boolean) =>
  ({ type: ActionTypes.SET_CREATE_GROUP_DRAWER_OPEN, payload: open } as const);

export const setCreateLikeCurrent = (createLikeCurrent: boolean) =>
  ({ type: ActionTypes.SET_CREATE_LIKE_CURRENT, payload: createLikeCurrent } as const);

export const openUserDrawerLike = (): AppThunk => dispatch => {
  dispatch(setCreateLikeCurrent(true));
  dispatch(setCreateUserDrawerOpen(true));
};

export const openGroupDrawerLike = (): AppThunk => dispatch => {
  dispatch(setCreateLikeCurrent(true));
  dispatch(setCreateGroupDrawerOpen(true));
};

export const selectUser = (userName: string) =>
  ({ type: ActionTypes.SELECT_USER, payload: userName } as const);

export const setDisabledPortfolioPermissions = (disabled: boolean) =>
  ({ type: ActionTypes.SET_DISABLED_PORTFOLIO_PERMISSIONS, payload: disabled } as const);

export const selectGroup = (groupName: string) =>
  ({ type: ActionTypes.SELECT_GROUP, payload: groupName } as const);

export const setUsers = (users: User[]) =>
  ({ type: ActionTypes.SET_USERS, payload: users } as const);

export const setGroups = (groups: string[]) =>
  ({ type: ActionTypes.SET_GROUPS, payload: groups } as const);

export const deleteUsers = (users: string[]) =>
  ({ type: ActionTypes.DELETE_USERS, payload: users } as const);

export const requestDeleteUsers = (): AppThunk => (dispatch, getState) =>
  axios
    .delete(`${baseUrl}api/user`, { params: { userNames: getState().admin.selectedUser } })
    .then(() => dispatch(deleteUsers([getState().admin.selectedUser])))
    .catch(error =>
      dispatch(enqueueSnackbar(NotificationLevel.ERROR, `Failed to delete user: ${error.message}`)),
    );

export const requestPasswordChange = (password: string): AppThunk => (dispatch, getState) => {
  return encryptString(password).then(encPass => {
    if (null == encPass) {
      password = btoa(password);
    } else if (encPass) {
      password = encPass;
    } else {
      dispatch(enqueueSnackbar(NotificationLevel.ERROR, 'Login invalid'));
      return;
    }
    axios
      .post(`${baseUrl}api/acup`, null, {
        params: { userName: getState().admin.selectedUser, password },
      })
      .then(() =>
        dispatch(
          enqueueSnackbar(
            NotificationLevel.SUCCESS,
            `Password changed for user ${getState().admin.selectedUser}`,
          ),
        ),
      )
      .catch(error =>
        dispatch(
          enqueueSnackbar(
            NotificationLevel.ERROR,
            `Failed to update users password: ${error.message}`,
          ),
        ),
      );
  });
};

export const deleteGroups = (groups: string[]) =>
  ({ type: ActionTypes.DELETE_GROUPS, payload: groups } as const);

export const requestDeleteGroups = (): AppThunk => (dispatch, getState) =>
  axios
    .delete(`${baseUrl}api/userGroup`, { params: { groupNames: getState().admin.selectedGroup } })
    .then(() => dispatch(deleteGroups([getState().admin.selectedGroup])))
    .catch(error =>
      dispatch(
        enqueueSnackbar(NotificationLevel.ERROR, `Failed to delete group: ${error.message}`),
      ),
    );

export const createGroupLike = (groupName: string, likeGroup: string) =>
  ({ type: ActionTypes.CREATE_GROUP_LIKE, payload: { groupName, likeGroup } } as const);

export const createUserLike = (
  sourceUser: string,
  userName: string,
  firstName: string,
  lastName: string,
) =>
  ({
    type: ActionTypes.CREATE_USER_LIKE,
    payload: { sourceUser, userName, firstName, lastName },
  } as const);

export const requestCreateGroupLike = (groupName: string): AppThunk => (dispatch, getState) =>
  axios
    .post(`${baseUrl}api/userGroup`, {
      groupName,
      likeGroup: getState().admin.createLikeCurrent && getState().admin.selectedGroup,
    })
    .then(() => {
      dispatch(createGroupLike(groupName, getState().admin.selectedGroup));
      dispatch(fetchPermissions());
      dispatch(selectGroup(groupName));
    })
    .catch(error =>
      dispatch(
        enqueueSnackbar(NotificationLevel.ERROR, `Failed to create group: ${error.message}`),
      ),
    );

export const requestCreateUserLike = (
  userName: string,
  firstName: string,
  lastName: string,
  password: string,
): AppThunk => (dispatch, getState) => {
  const likeUserName = getState().admin.createLikeCurrent && getState().admin.selectedUser;
  axios
    .post(`${baseUrl}api/user`, {
      user: { userName, firstName, lastName },
      password,
      limitedAccess: false,
      locked: false,
      likeUserName,
    })
    .then(() => {
      dispatch(createUserLike(likeUserName, userName, firstName, lastName));
      dispatch(selectUser(userName));
    })
    .catch(error =>
      dispatch(enqueueSnackbar(NotificationLevel.ERROR, `Failed to create user: ${error.message}`)),
    );
};

export const addUsersToGroup = (userNames: string[], group: string) =>
  ({ type: ActionTypes.ADD_USERS_TO_GROUP, payload: { userNames, group } } as const);

export const removeUsersFromGroup = (userNames: string[], group: string) =>
  ({ type: ActionTypes.REMOVE_USERS_FROM_GROUP, payload: { userNames, group } } as const);

export const addLoadingGroupMembers = (userNames: string[], group: string) =>
  ({ type: ActionTypes.ADD_LOADING_GROUP_MEMBERS, payload: { userNames, group } } as const);

export const removeLoadingGroupMembers = (userNames: string[], group: string) =>
  ({ type: ActionTypes.REMOVE_LOADING_GROUP_MEMBERS, payload: { userNames, group } } as const);

export const setPermissions = (allPermissions: AllPermissions) =>
  ({ type: ActionTypes.SET_PERMISSIONS, payload: allPermissions } as const);

export const setComponentPermissions = (permissions: ComponentPermissions) =>
  ({ type: ActionTypes.SET_COMPONENT_PERMISSIONS, payload: permissions } as const);

export const setPortfolioPermissions = (permissions: PortfolioPermissions) =>
  ({ type: ActionTypes.SET_PORTFOLIO_PERMISSIONS, payload: permissions } as const);

export const setGroupPortfolioPermissions = (group: string, permissions: string[]) =>
  ({
    type: ActionTypes.SET_GROUP_PORTFOLIO_PERMISSIONS,
    payload: { group, permissions },
  } as const);

export const setFolderPermission = (change: ChangeFolderPermission) =>
  ({ type: ActionTypes.SET_FOLDER_PERMISSION, payload: change } as const);

export const setContextPermissionRule = (rule: ContextPermissionRule) =>
  ({ type: ActionTypes.SET_CONTEXT_PERMISSION_RULE, payload: rule } as const);

export const requestAddUsersToGroup = (
  userNames: string[],
  group: string,
): AppThunk => dispatch => {
  dispatch(addLoadingGroupMembers(userNames, group));

  axios
    .put(`${baseUrl}api/userGroup`, {
      groupName: group,
      operation: 'ADD',
      userNames: userNames,
    })
    .then(() => dispatch(addUsersToGroup(userNames, group)))
    .catch(error =>
      dispatch(
        enqueueSnackbar(
          NotificationLevel.ERROR,
          `Unable to add users to group: ${getErrorMessage(error)}`,
        ),
      ),
    )
    .finally(() => dispatch(removeLoadingGroupMembers(userNames, group)));
};

export const requestAddUsersToSelectedGroup = (userNames: string[]): AppThunk => (
  dispatch,
  getState,
) => dispatch(requestAddUsersToGroup(userNames, getState().admin.selectedGroup));

export const requestRemoveUsersFromGroup = (
  userNames: string[],
  group: string,
): AppThunk => dispatch => {
  dispatch(addLoadingGroupMembers(userNames, group));

  axios
    .put(`${baseUrl}api/userGroup`, { groupName: group, operation: 'REMOVE', userNames: userNames })
    .then(() => dispatch(removeUsersFromGroup(userNames, group)))
    .catch(error =>
      dispatch(
        enqueueSnackbar(
          NotificationLevel.ERROR,
          `Unable to remove users from group: ${getErrorMessage(error)}`,
        ),
      ),
    )
    .finally(() => dispatch(removeLoadingGroupMembers(userNames, group)));
};

export const requestRemoveUsersFromSelectedGroup = (userNames: string[]): AppThunk => (
  dispatch,
  getState,
) => dispatch(requestRemoveUsersFromGroup(userNames, getState().admin.selectedGroup));

export const getAllUsersAndGroups = (changeSelections: boolean): AppThunk => async dispatch => {
  try {
    const [usersResponse, groupsResponse] = await Promise.all([
      axios.get<User[]>(`${baseUrl}api/user`),
      axios.get<string[]>(`${baseUrl}api/userGroup`),
    ]);

    // Users must be set before groups for includedGroupWhenSelected purposes (see reducer)
    dispatch(setUsers(usersResponse.data));
    dispatch(setGroups(groupsResponse.data));

    if (changeSelections) {
      dispatch(selectUser(usersResponse.data?.[0].userName));
      dispatch(selectGroup(groupsResponse.data?.[0]));
    }
  } catch (error) {
    dispatch(
      enqueueSnackbar(
        NotificationLevel.WARN,
        `Unable to retrieve users/groups: ${getErrorMessage(error)}`,
      ),
    );
  }
};

export const checkAllGroupMembers = (filteredIds: string[]): AppThunk => (dispatch, getState) => {
  const { selectedGroup } = getState().admin;

  const usersInGroup = getState()
    .admin.users.filter(u => u.groups.includes(selectedGroup))
    .map(u => u.userName);

  const usersNotInGroup = getState()
    .admin.users.filter(u => !usersInGroup.includes(u.userName))
    .map(u => u.userName);

  if (usersInGroup.length === filteredIds.length)
    dispatch(requestRemoveUsersFromSelectedGroup(usersInGroup));
  else dispatch(requestAddUsersToSelectedGroup(usersNotInGroup));
};

const fetchComponentPermissions = (): AppThunk => dispatch =>
  axios
    .get(`${baseUrl}api/componentPermissions`)
    .then(response => dispatch(setComponentPermissions(response.data)))
    .catch(error =>
      dispatch(
        enqueueSnackbar(
          NotificationLevel.WARN,
          `Unable to retrieve permissions: ${getErrorMessage(error)}`,
        ),
      ),
    );

const fetchPortfolioPermissions = (): AppThunk => dispatch =>
  axios
    .get<PortfolioPermissions>(`${baseUrl}api/portfolioPermissions`)
    .then(response => {
      dispatch(setDisabledPortfolioPermissions(false));
      return dispatch(
        setPortfolioPermissions(
          // Remove /* from folder names so the path matches what comes from api/portfolioHierarchy
          Object.keys(response.data)
            .map(groupName => ({
              groupName,
              permissions: response.data[groupName].map(j =>
                j.endsWith('/*') ? j.substring(0, j.length - 2) : j,
              ),
            }))
            .reduce((acc, cur) => ({ ...acc, [cur.groupName]: cur.permissions }), {}),
        ),
      );
    })
    .catch(error =>
      dispatch(
        enqueueSnackbar(
          NotificationLevel.WARN,
          `Unable to retrieve permissions: ${getErrorMessage(error)}`,
        ),
      ),
    );

export const fetchPermissions = (): AppThunk => dispatch => {
  axios
    .get<AllPermissions>(`${baseUrl}api/allPermissions`)
    .then(response => dispatch(setPermissions(response.data)))
    .catch(error =>
      dispatch(
        enqueueSnackbar(
          NotificationLevel.WARN,
          `Unable to retrieve permissions: ${getErrorMessage(error)}`,
        ),
      ),
    );

  dispatch(fetchPortfolioPermissions());
  dispatch(fetchComponentPermissions());
};

export const setPortfolioPermission = (
  paths: { path: string; folder: boolean }[],
  { date, id }: FullDateContextItem,
  hierarchyName: string,
): AppThunk => async (dispatch, getState) => {
  if (!getState().admin.disabledPortfolioPermissions) {
    dispatch(setDisabledPortfolioPermissions(true));
    try {
      await axios.put(
        `${baseUrl}api/portfolioPermissions`,
        paths.map(p => (p.folder ? `${p.path}*` : p.path)),
        {
          params: {
            roleName: getState().admin.selectedGroup,
            date,
            id,
            hierarchyName,
          },
        },
      );
      dispatch(fetchPortfolioPermissions());
    } catch (error) {
      dispatch(setDisabledPortfolioPermissions(false));
      dispatch(
        enqueueSnackbar(
          NotificationLevel.ERROR,
          `Unable to change permissions: ${getErrorMessage(error)}`,
        ),
      );
    }
  }
};

export const requestSetComponentPermission = (
  key: keyof ComponentPermission,
  checked: boolean,
): AppThunk => async (dispatch, getState) => {
  const newComponentPermissions: ComponentPermission = {
    ...getState().admin.componentPermissions[getState().admin.selectedGroup],
    [key]: checked,
  };

  try {
    await axios.put(`${baseUrl}api/componentPermissions`, newComponentPermissions, {
      params: { roleName: getState().admin.selectedGroup },
    });
  } catch (error) {
    dispatch(
      enqueueSnackbar(
        NotificationLevel.ERROR,
        `Unable to change permissions: ${getErrorMessage(error)}`,
      ),
    );
  } finally {
    // TODO consider making this unnecessary by returning the resulting component permissions
    // in the response to the PUT call above.
    dispatch(fetchComponentPermissions());
  }
};

export const requestSetFolderPermission = (change: ChangeFolderPermission): AppThunk => (
  dispatch,
  getState,
) => {
  const changeWithGroup = { ...change, groupName: getState().admin.selectedGroup };
  axios
    .put(`${baseUrl}api/folderPermission`, changeWithGroup)
    .then(() => dispatch(setFolderPermission(changeWithGroup)))
    .catch(error =>
      dispatch(
        enqueueSnackbar(
          NotificationLevel.ERROR,
          `Unable to set permissions: ${getErrorMessage(error)}`,
        ),
      ),
    );
};

export const requestSetContextPermissionRule = (rule: Partial<ContextPermissionRule>): AppThunk => (
  dispatch,
  getState,
) => {
  const { selectedGroup } = getState().admin;

  const newRule: ContextPermissionRule = {
    ...(getState().admin.allPermissions.contextPermissions.find(
      c => c.groupName === selectedGroup,
    ) || { groupName: selectedGroup }),
    ...rule,
  };

  axios
    .put(`${baseUrl}api/contextPermissionRule`, newRule)
    .then(() => dispatch(setContextPermissionRule(newRule)))
    .catch(error =>
      dispatch(
        enqueueSnackbar(
          NotificationLevel.ERROR,
          `Unable to set permissions: ${getErrorMessage(error)}`,
        ),
      ),
    );
};

export const setUserFirstLastName = (user: string, firstName: string, lastName: string) =>
  ({ type: ActionTypes.SET_USER_FIRST_LAST_NAME, payload: { user, firstName, lastName } } as const);

export const setUserFirstName = (firstName: string): AppThunk => (dispatch, getState) => {
  const selectedUser = getState().admin?.selectedUser;
  const targetUser = getState().admin.users.find(u => u.userName === selectedUser);
  dispatch(setUserFirstLastName(selectedUser, firstName, targetUser.lastName));
};

export const setUserLastName = (lastName: string): AppThunk => (dispatch, getState) => {
  const selectedUser = getState().admin?.selectedUser;
  const targetUser = getState().admin.users.find(u => u.userName === selectedUser);
  dispatch(setUserFirstLastName(selectedUser, targetUser.firstName, lastName));
};

export const _setUserLocked = (user: string, locked: boolean) =>
  ({ type: ActionTypes.SET_USER_LOCKED, payload: { user, locked } } as const);

export const setUserLocked = (locked: boolean): AppThunk => (dispatch, getState) => {
  const selectedUser = getState().admin?.selectedUser;
  dispatch(_setUserLocked(selectedUser, locked));
};

export const requestSetUserFirstLastName = (): AppThunk => async (dispatch, getState) => {
  try {
    const selectedUser = getState().admin?.selectedUser;
    const targetUser = getState().admin.users.find(u => u.userName === selectedUser);
    await axios.put(`${baseUrl}api/userInfo`, {
      username: selectedUser,
      firstname: targetUser.firstName,
      lastname: targetUser.lastName,
    });
    dispatch(enqueueSnackbar(NotificationLevel.SUCCESS, 'Name saved succesfully'));
  } catch (error) {
    dispatch(getAllUsersAndGroups(false));
    dispatch(
      enqueueSnackbar(
        NotificationLevel.ERROR,
        `Unable to set first and last: ${getErrorMessage(error)}`,
      ),
    );
  }
};

export const requestUnlockUser = (): AppThunk => async (dispatch, getState) => {
  try {
    const selectedUser = getState().admin?.selectedUser;
    await axios.post(`${baseUrl}api/unlockUser`, null, {
      params: { user: selectedUser },
    });
    dispatch(setUserLocked(false));
    dispatch(getAllUsersAndGroups(false));
    dispatch(enqueueSnackbar(NotificationLevel.SUCCESS, 'User unlocked succesfully'));
  } catch (error) {
    dispatch(getAllUsersAndGroups(false));
    dispatch(
      enqueueSnackbar(NotificationLevel.ERROR, `Unable to unlock user: ${getErrorMessage(error)}`),
    );
  }
};

export const requestLockUser = (): AppThunk => async (dispatch, getState) => {
  try {
    const selectedUser = getState().admin?.selectedUser;
    await axios.post(`${baseUrl}api/lockUser`, null, {
      params: { user: selectedUser },
    });
    dispatch(setUserLocked(true));
    dispatch(getAllUsersAndGroups(false));
    dispatch(enqueueSnackbar(NotificationLevel.SUCCESS, 'User locked succesfully'));
  } catch (error) {
    dispatch(getAllUsersAndGroups(false));
    dispatch(
      enqueueSnackbar(NotificationLevel.ERROR, `Unable to lock user: ${getErrorMessage(error)}`),
    );
  }
};
