import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { updateWorkspaceCurrency } from '../../redux/ActionCreators';
import { AppState } from '../../redux/configureStore';

const useUrlCurrency = () => {
  const dispatch = useDispatch();
  const userCcy = useSelector(
    (store: AppState) => store.user?.userInfo?.userPreferences?.selectedCurrency,
  );
  const urlParams = new URLSearchParams(useLocation().search);
  const urlCcy = urlParams.get('ccy');

  useEffect(() => {
    if (urlCcy) {
      dispatch(updateWorkspaceCurrency(urlCcy));
    }
  }, [urlCcy, userCcy, dispatch]);
};

export default useUrlCurrency;
