import { toggleSidePanel } from './ActionCreators';
import * as ActionTypes from './ActionTypes';

type Action = ReturnType<typeof toggleSidePanel>;

export const sidePanel = (
  state = { open: false, reportDetails: null, positions: [] },
  action: Action,
) => {
  switch (action.type) {
    case ActionTypes.TOGGLE_SIDE_PANEL:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
