import { editor } from 'monaco-editor';
import colors from '../theme/colors';
import TokenFactory from '../TokenFactory';
import AScriptLanguageService from './AScriptLanguageService';

type ThemeRule = Omit<editor.ITokenThemeRule, 'token'>;

enum RuleClass {
  UNSTYLED,
  COMMENT,
  SEMICOLON,
  ERROR,
  IMPORT_KW,
  IMPORT_ID,
  PARAMETERIZATION,
  KEYWORD,
  TYPE,
  IDENTIFIER,
  FUNCTION,
  NUMBER,
  STRING,
  BOOLEAN,
  OPERATOR,
  KEY_EXPRESSION,
  R_BLOCK_BOOKEND,
  R_BLOCK_CONTENT,
  DELIMITER,
}

const ruleClasses = [
  RuleClass.UNSTYLED,
  RuleClass.COMMENT,
  RuleClass.SEMICOLON,
  RuleClass.ERROR,
  RuleClass.IMPORT_KW,
  RuleClass.IMPORT_ID,
  RuleClass.PARAMETERIZATION,
  RuleClass.KEYWORD,
  RuleClass.TYPE,
  RuleClass.IDENTIFIER,
  RuleClass.FUNCTION,
  RuleClass.NUMBER,
  RuleClass.STRING,
  RuleClass.BOOLEAN,
  RuleClass.OPERATOR,
  RuleClass.KEY_EXPRESSION,
  RuleClass.R_BLOCK_BOOKEND,
  RuleClass.R_BLOCK_CONTENT,
  RuleClass.DELIMITER,
];

type RuleClassMap<T> = { [ruleClass in RuleClass]: T };

const tokens: RuleClassMap<string[]> = {
  [RuleClass.UNSTYLED]: [
    'WS',
    'PERIOD',
    'IMPORT_PERIOD',
    'EQUAL_SIGN',
    'COMMA',
    'COLON',
    'NEW_LINE',
    'R_BLOCK_CONTENT',
  ],
  [RuleClass.COMMENT]: ['COMMENT', 'NUMBER_SIGN'],
  [RuleClass.SEMICOLON]: ['SEMICOLON', 'IMPORT_SEMICOLON'],
  [RuleClass.ERROR]: ['ERROR', 'UNRECOGNIZED'],
  [RuleClass.IMPORT_KW]: ['KW_IMPORT', 'KW_AS'],
  [RuleClass.IMPORT_ID]: ['IMPORT_ID'],
  [RuleClass.PARAMETERIZATION]: ['IMPORT_PARAMETERIZATION', 'IMPORT_LANGLE', 'IMPORT_RANGLE'],
  [RuleClass.KEYWORD]: ['KW_IF', 'KW_ELSE', 'KW_WHILE', 'KW_RETURN', 'KW_CONTINUE', 'KW_REQUIRES'],
  [RuleClass.TYPE]: ['TYPE', 'KW_DOUBLE_VECTOR', 'CAST_OPERATOR'],
  [RuleClass.IDENTIFIER]: ['IDENTIFIER'],
  [RuleClass.FUNCTION]: [
    'KW_PRINT',
    'KW_NATIVE_FUNCTION',
    'KW_NATIVE_INSTANCE_METHOD',
    'KW_SUBSTR',
    'SUBSTR_EXPRESSION',
    'KW_R_ASSIGN',
    'KW_ARM_ASSIGN',
    'KW_MATH',
    'MATH_METHOD',
  ],
  [RuleClass.NUMBER]: ['INT_LITERAL', 'DOUBLE_LITERAL'],
  [RuleClass.STRING]: ['STRING_LITERAL', 'DOUBLE_QUOTE'],
  [RuleClass.BOOLEAN]: ['BOOLEAN_LITERAL'],
  [RuleClass.OPERATOR]: [
    'LOGICAL_OR',
    'LOGICAL_AND',
    'BITWISE_OR',
    'BITWISE_XOR',
    'BITWISE_AND',
    'COMP_EQ',
    'COMP_NEQ',
    'COMP_LT',
    'COMP_GT',
    'COMP_LEQ',
    'COMP_GEQ',
    'ARITH_ADD',
    'ARITH_SUBTRACT',
    'ARITH_MULTIPLY',
    'ARITH_DIVIDE',
    'ARITH_MOD',
    'BITWISE_COMPLEMENT',
    'LOGICAL_NOT',
    'INCREMENT',
    'DECREMENT',
  ],
  [RuleClass.KEY_EXPRESSION]: [
    'KEY_EXPRESSION',
    'TOTAL_EXPRESSION',
    'VALUE_KEY_EXPRESSION',
    'INSTANCE_KEY_EXPRESSION',
    'KW_KEY',
    'KW_TOTAL',
    'KW_VALUE_KEY',
    'KW_GET',
    'KW_KEY_MODIFIER',
    'KEY_ACCESSOR',
  ],
  [RuleClass.R_BLOCK_BOOKEND]: ['KW_R_BLOCK_BEGIN', 'KW_R_BLOCK_END'],
  [RuleClass.R_BLOCK_CONTENT]: ['R_BLOCK_CHAR'],
  [RuleClass.DELIMITER]: ['L_PAREN', 'R_PAREN', 'L_BRACKET', 'R_BRACKET', 'L_BRACE', 'R_BRACE'],
};

const themeRules: RuleClassMap<ThemeRule> = {
  [RuleClass.UNSTYLED]: {
    foreground: colors.black,
  },

  [RuleClass.COMMENT]: {
    foreground: colors.lightGray,
    fontStyle: 'italic',
  },

  [RuleClass.SEMICOLON]: {
    foreground: colors.lightGray,
  },

  [RuleClass.ERROR]: {
    foreground: colors.red,
  },

  [RuleClass.IMPORT_KW]: {
    foreground: colors.rose,
    fontStyle: 'bold',
  },

  [RuleClass.IMPORT_ID]: {
    foreground: colors.twilightBlue,
  },

  [RuleClass.PARAMETERIZATION]: {
    foreground: colors.teal,
  },

  [RuleClass.KEYWORD]: {
    foreground: colors.purple,
    fontStyle: 'bold',
  },

  [RuleClass.TYPE]: {
    foreground: colors.pink,
    fontStyle: 'bold',
  },

  [RuleClass.IDENTIFIER]: {
    foreground: colors.darkGreen,
    fontStyle: 'italic',
  },

  [RuleClass.FUNCTION]: {
    foreground: colors.rose,
  },

  [RuleClass.NUMBER]: {
    foreground: colors.giraffe,
  },

  [RuleClass.STRING]: {
    foreground: colors.green,
  },

  [RuleClass.BOOLEAN]: {
    foreground: colors.teal,
    fontStyle: 'bold',
  },

  [RuleClass.OPERATOR]: {
    foreground: colors.twilightBlue,
  },

  [RuleClass.KEY_EXPRESSION]: {
    foreground: colors.orange,
  },

  [RuleClass.R_BLOCK_BOOKEND]: {
    foreground: colors.pricklyPink,
    fontStyle: 'underline',
  },

  [RuleClass.R_BLOCK_CONTENT]: {
    foreground: colors.black,
  },

  [RuleClass.DELIMITER]: {
    foreground: colors.midGray,
    fontStyle: 'bold',
  },
};

const createThemeRule = (ruleClass: RuleClass) => (ruleName: string): editor.ITokenThemeRule => ({
  token: TokenFactory.createTokenName(AScriptLanguageService.LANGUAGE_ID, ruleName),
  ...themeRules[ruleClass],
});

const rules: editor.ITokenThemeRule[] = ruleClasses.flatMap(ruleClass =>
  tokens[ruleClass].map(createThemeRule(ruleClass)),
);

export default rules;
