// @ts-strict-ignore
import { createDefaultConfig, ScenariosConfig, ScenarioSet } from '../../model/scenariosConfig';
import { DataSourceItemForDx } from '../../shared/dataTypes';
import { COPY_SCENARIOS_CONFIG } from '../ActionTypes';
import { AppThunk } from '../configureStore';

export enum ReportScenarioDrawerActionType {
  OPEN = 'reportScenarioDrawer/open',
  CLOSE = 'reportScenarioDrawer/close',
  SET_SETS = 'reportScenarioDrawer/setSets',
  SET_TIMESTEPS = 'reportScenarioDrawer/setTimesteps',
}

export const openReportScenarioDrawer = (sequenceId: number) =>
  ({ type: ReportScenarioDrawerActionType.OPEN, payload: { sequenceId } } as const);

export const loadReportScenarioDrawer = (
  sequenceId: number,
  getScenarioSets: (scenariosConfig: ScenariosConfig) => void,
): AppThunk => (dispatch, getState) => {
  const scenariosConfig =
    getState().report.reportDefinition[sequenceId]?.scenariosConfig || createDefaultConfig();

  dispatch({
    reducerId: 'workspace',
    type: COPY_SCENARIOS_CONFIG,
    payload: scenariosConfig,
  });

  dispatch(openReportScenarioDrawer(sequenceId));
  getScenarioSets(scenariosConfig);
};

export const closeReportScenarioDrawer = () =>
  ({ type: ReportScenarioDrawerActionType.CLOSE } as const);

export const setReportScenarioDrawerSets = (sets: ScenarioSet[]) =>
  ({ type: ReportScenarioDrawerActionType.SET_SETS, payload: sets } as const);

export const setReportScenarioDrawerTimesteps = (timesteps: DataSourceItemForDx<string>[]) =>
  ({ type: ReportScenarioDrawerActionType.SET_TIMESTEPS, payload: timesteps } as const);
