import { IDisposable } from 'monaco-editor';

export enum CompletionProviderType {
  KEY,
  MODIFIER,
  METHODS,
}

export type CompletionProviderRegistrations = {
  [id: string]: IDisposable | null;
};
