import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { AppState } from '../../redux/configureStore';
import { openWorkspacePage } from '../../redux/ui/actionCreators';
import { fetchLandingWorkspace } from '../../redux/UserProfileActionCreators';

/**
 * Landing navigation precedence:
 *   1. Workspace from URL query string (if any)
 *   2. Default workspace (if any)
 *   3. None (land on Home Page)
 */
const useWorkspaceLanding = () => {
  const [navigationCheckComplete, setNavigationCheckComplete] = useState(false);

  const userLoggedIn = useSelector((state: AppState) => state.user.loggedIn);
  const hasUserInfo = useSelector((state: AppState) => state.user.hasUserInfo);
  const defaultWorkspace = useSelector(
    (state: AppState) => state.user.userInfo.userPreferences?.defaultWorkspace,
  );
  const dispatch = useDispatch();

  const urlParams = new URLSearchParams(useLocation().search);
  const workspaceFromUrl = urlParams.get('workspace');
  const workspacePath = (workspaceFromUrl || defaultWorkspace) ?? null;

  useEffect(() => {
    const navigationCheckRequired = userLoggedIn && hasUserInfo;

    if (navigationCheckRequired && !navigationCheckComplete && workspacePath) {
      console.log('Opening workspace page...');
      dispatch(openWorkspacePage());
      dispatch(fetchLandingWorkspace(workspacePath));
    }

    setNavigationCheckComplete(navigationCheckRequired);
  }, [navigationCheckComplete, userLoggedIn, hasUserInfo, workspacePath, dispatch]);
};

export default useWorkspaceLanding;
