import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { RoundedButton } from 'algo-react-dataviz';
import { FC, PropsWithChildren } from 'react';

interface Props {
  open: boolean;
  onRequestSubmit: () => void;
  onRequestClose?: () => void;
  onBackdropClick?: () => void;
  headerText: string;
  confirmButtonText?: string;
  confirmButtonDisabled?: boolean;
  cancelButtonText?: string;
  submitColor?: 'primary' | 'secondary';
}

const MainModal: FC<PropsWithChildren<Props>> = ({
  open,
  onRequestSubmit,
  onRequestClose,
  headerText,
  children,
  confirmButtonText,
  confirmButtonDisabled,
  cancelButtonText,
  submitColor,
  onBackdropClick,
}) => {
  return (
    <Dialog open={open} onClose={onRequestClose} onBackdropClick={onBackdropClick}>
      <DialogTitle>{headerText}</DialogTitle>
      <DialogContent>
        <DialogContentText>{children}</DialogContentText>
      </DialogContent>
      <DialogActions style={{ minWidth: 300 }}>
        <RoundedButton
          variant='contained'
          color={submitColor || 'primary'}
          disabled={confirmButtonDisabled}
          onClick={onRequestSubmit}
        >
          {confirmButtonText || 'Yes'}
        </RoundedButton>
        {onRequestClose && (
          <RoundedButton variant='outlined' color='primary' onClick={onRequestClose}>
            {cancelButtonText || 'Cancel'}
          </RoundedButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default MainModal;
