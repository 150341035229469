import { Dispatch } from '@reduxjs/toolkit';
import axios from 'axios';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { baseUrl } from '../components/shared/environment';
import { enqueueSnackbar } from '../redux/ActionCreators';
import { NotificationLevel } from '../shared/constants';
import { Sandboxes } from '../shared/dataTypes';

export const getSandboxes = async (dispatch: Dispatch) => {
  try {
    const response = await axios.get<Sandboxes>(`${baseUrl}api/sandboxes`);
    return response.data;
  } catch {
    dispatch(enqueueSnackbar(NotificationLevel.ERROR, 'Error retrieving sandbox list'));
  }
};

const useSandboxes = () => {
  const [sandboxes, setSandboxes] = useState<Sandboxes>();
  const dispatch = useDispatch();

  // The component is responsible for calling this function, which could be in a useEffect
  // or could be in an event handler.
  const fetchSandboxes = useCallback(() => {
    getSandboxes(dispatch).then(data => {
      data && setSandboxes(data);
    });
  }, [dispatch]);

  return { sandboxes, fetchSandboxes };
};

export default useSandboxes;
