// @ts-strict-ignore
import { Monaco } from '@monaco-editor/react';
import { languages } from 'monaco-editor';
import { EditorLanguage } from '../../editorLanguage';
import { CompletionProviderRegistrations, CompletionProviderType } from './completionProviderType';

class CompletionProviderService {
  // Some (language-level) providers apply to all editor models of a given language;
  // some (e.g. characteristic key completion) are editor instance-level.
  // So we give them different types of ID for managing their registration.
  static getId = (type: CompletionProviderType, modelId: string | null = null) =>
    `${modelId ?? 'all'}/${type}`;

  private registrations: CompletionProviderRegistrations = {};

  constructor(private readonly language: EditorLanguage, private monaco: Monaco) {}

  hasProvider = (id: string) => !!this.registrations[id];

  registerProvider = (id: string, provider: languages.CompletionItemProvider) => {
    if (this.hasProvider(id)) {
      this.clearProvider(id);
    }

    this.registrations[id] = this.monaco.languages.registerCompletionItemProvider(
      this.language,
      provider,
    );
  };

  clearProvider = (id: string) => {
    if (!this.hasProvider(id)) {
      return;
    }

    this.registrations[id].dispose();
    this.registrations[id] = null;
  };

  clearAllProviders = () => Object.keys(this.registrations).forEach(this.clearProvider);
}

export default CompletionProviderService;
