// Generated from src/lang/folia/FoliaLexer.g4 by ANTLR 4.9.0-SNAPSHOT


import { ATN } from "antlr4ts/atn/ATN";
import { ATNDeserializer } from "antlr4ts/atn/ATNDeserializer";
import { CharStream } from "antlr4ts/CharStream";
import { Lexer } from "antlr4ts/Lexer";
import { LexerATNSimulator } from "antlr4ts/atn/LexerATNSimulator";
import { NotNull } from "antlr4ts/Decorators";
import { Override } from "antlr4ts/Decorators";
import { RuleContext } from "antlr4ts/RuleContext";
import { Vocabulary } from "antlr4ts/Vocabulary";
import { VocabularyImpl } from "antlr4ts/VocabularyImpl";

import * as Utils from "antlr4ts/misc/Utils";


export class FoliaLexer extends Lexer {
	public static readonly KW_KEY = 1;
	public static readonly KEY_EXPRESSION = 2;
	public static readonly KEY_ACCESSOR = 3;
	public static readonly BOOLEAN_LITERAL = 4;
	public static readonly DOUBLE_LITERAL = 5;
	public static readonly INT_LITERAL = 6;
	public static readonly STRING_LITERAL = 7;
	public static readonly NULL_LITERAL = 8;
	public static readonly DOUBLE_ARRAY_LITERAL = 9;
	public static readonly INT_ARRAY_LITERAL = 10;
	public static readonly STRING_ARRAY_LITERAL = 11;
	public static readonly PERIOD = 12;
	public static readonly COMMA = 13;
	public static readonly DOUBLE_QUOTE = 14;
	public static readonly L_PAREN = 15;
	public static readonly R_PAREN = 16;
	public static readonly L_BRACKET = 17;
	public static readonly R_BRACKET = 18;
	public static readonly L_BRACE = 19;
	public static readonly R_BRACE = 20;
	public static readonly LOGICAL_NOT = 21;
	public static readonly LOGICAL_AND = 22;
	public static readonly LOGICAL_OR = 23;
	public static readonly COMP_EQ = 24;
	public static readonly COMP_NEQ = 25;
	public static readonly COMP_LT = 26;
	public static readonly COMP_GT = 27;
	public static readonly COMP_LEQ = 28;
	public static readonly COMP_GEQ = 29;
	public static readonly COMP_IN = 30;
	public static readonly WS = 31;
	public static readonly UNRECOGNIZED = 32;

	// tslint:disable:no-trailing-whitespace
	public static readonly channelNames: string[] = [
		"DEFAULT_TOKEN_CHANNEL", "HIDDEN",
	];

	// tslint:disable:no-trailing-whitespace
	public static readonly modeNames: string[] = [
		"DEFAULT_MODE",
	];

	public static readonly ruleNames: string[] = [
		"KW_KEY", "KEY_EXPRESSION", "KEY_ACCESSOR", "BOOLEAN_LITERAL", "DOUBLE_LITERAL", 
		"INT_LITERAL", "STRING_LITERAL", "NULL_LITERAL", "DOUBLE_ARRAY_LITERAL", 
		"INT_ARRAY_LITERAL", "STRING_ARRAY_LITERAL", "DIGIT", "NEGATIVE", "PERIOD", 
		"COMMA", "DOUBLE_QUOTE", "L_PAREN", "R_PAREN", "L_BRACKET", "R_BRACKET", 
		"L_BRACE", "R_BRACE", "NOT_R_PAREN", "NOT_R_BRACKET", "NOT_R_BRACE", "NOT_DOUBLE_QUOTE", 
		"NOT_NEW_LINE", "LOGICAL_NOT", "LOGICAL_AND", "LOGICAL_OR", "COMP_EQ", 
		"COMP_NEQ", "COMP_LT", "COMP_GT", "COMP_LEQ", "COMP_GEQ", "COMP_IN", "WS", 
		"UNRECOGNIZED",
	];

	private static readonly _LITERAL_NAMES: Array<string | undefined> = [
		undefined, "'key'", undefined, undefined, undefined, undefined, undefined, 
		undefined, undefined, undefined, undefined, undefined, "'.'", "','", "'\"'", 
		"'('", "')'", "'['", "']'", "'{'", "'}'", undefined, undefined, undefined, 
		"'=='", "'!='", "'<'", "'>'", "'<='", "'>='",
	];
	private static readonly _SYMBOLIC_NAMES: Array<string | undefined> = [
		undefined, "KW_KEY", "KEY_EXPRESSION", "KEY_ACCESSOR", "BOOLEAN_LITERAL", 
		"DOUBLE_LITERAL", "INT_LITERAL", "STRING_LITERAL", "NULL_LITERAL", "DOUBLE_ARRAY_LITERAL", 
		"INT_ARRAY_LITERAL", "STRING_ARRAY_LITERAL", "PERIOD", "COMMA", "DOUBLE_QUOTE", 
		"L_PAREN", "R_PAREN", "L_BRACKET", "R_BRACKET", "L_BRACE", "R_BRACE", 
		"LOGICAL_NOT", "LOGICAL_AND", "LOGICAL_OR", "COMP_EQ", "COMP_NEQ", "COMP_LT", 
		"COMP_GT", "COMP_LEQ", "COMP_GEQ", "COMP_IN", "WS", "UNRECOGNIZED",
	];
	public static readonly VOCABULARY: Vocabulary = new VocabularyImpl(FoliaLexer._LITERAL_NAMES, FoliaLexer._SYMBOLIC_NAMES, []);

	// @Override
	// @NotNull
	public get vocabulary(): Vocabulary {
		return FoliaLexer.VOCABULARY;
	}
	// tslint:enable:no-trailing-whitespace


	constructor(input: CharStream) {
		super(input);
		this._interp = new LexerATNSimulator(FoliaLexer._ATN, this);
	}

	// @Override
	public get grammarFileName(): string { return "FoliaLexer.g4"; }

	// @Override
	public get ruleNames(): string[] { return FoliaLexer.ruleNames; }

	// @Override
	public get serializedATN(): string { return FoliaLexer._serializedATN; }

	// @Override
	public get channelNames(): string[] { return FoliaLexer.channelNames; }

	// @Override
	public get modeNames(): string[] { return FoliaLexer.modeNames; }

	public static readonly _serializedATN: string =
		"\x03\uC91D\uCABA\u058D\uAFBA\u4F53\u0607\uEA8B\uC241\x02\"\u013F\b\x01" +
		"\x04\x02\t\x02\x04\x03\t\x03\x04\x04\t\x04\x04\x05\t\x05\x04\x06\t\x06" +
		"\x04\x07\t\x07\x04\b\t\b\x04\t\t\t\x04\n\t\n\x04\v\t\v\x04\f\t\f\x04\r" +
		"\t\r\x04\x0E\t\x0E\x04\x0F\t\x0F\x04\x10\t\x10\x04\x11\t\x11\x04\x12\t" +
		"\x12\x04\x13\t\x13\x04\x14\t\x14\x04\x15\t\x15\x04\x16\t\x16\x04\x17\t" +
		"\x17\x04\x18\t\x18\x04\x19\t\x19\x04\x1A\t\x1A\x04\x1B\t\x1B\x04\x1C\t" +
		"\x1C\x04\x1D\t\x1D\x04\x1E\t\x1E\x04\x1F\t\x1F\x04 \t \x04!\t!\x04\"\t" +
		"\"\x04#\t#\x04$\t$\x04%\t%\x04&\t&\x04\'\t\'\x04(\t(\x03\x02\x03\x02\x03" +
		"\x02\x03\x02\x03\x03\x03\x03\x03\x03\x03\x04\x03\x04\x06\x04[\n\x04\r" +
		"\x04\x0E\x04\\\x03\x04\x03\x04\x03\x05\x03\x05\x03\x05\x03\x05\x03\x05" +
		"\x03\x05\x03\x05\x03\x05\x03\x05\x03\x05\x03\x05\x03\x05\x03\x05\x03\x05" +
		"\x03\x05\x03\x05\x03\x05\x03\x05\x05\x05s\n\x05\x03\x06\x05\x06v\n\x06" +
		"\x03\x06\x06\x06y\n\x06\r\x06\x0E\x06z\x03\x06\x03\x06\x07\x06\x7F\n\x06" +
		"\f\x06\x0E\x06\x82\v\x06\x03\x06\x07\x06\x85\n\x06\f\x06\x0E\x06\x88\v" +
		"\x06\x03\x06\x03\x06\x06\x06\x8C\n\x06\r\x06\x0E\x06\x8D\x05\x06\x90\n" +
		"\x06\x03\x07\x05\x07\x93\n\x07\x03\x07\x06\x07\x96\n\x07\r\x07\x0E\x07" +
		"\x97\x03\b\x03\b\x07\b\x9C\n\b\f\b\x0E\b\x9F\v\b\x03\b\x03\b\x03\t\x03" +
		"\t\x03\t\x03\t\x03\t\x03\t\x03\t\x03\t\x05\t\xAB\n\t\x03\n\x03\n\x03\n" +
		"\x03\n\x07\n\xB1\n\n\f\n\x0E\n\xB4\v\n\x03\n\x03\n\x07\n\xB8\n\n\f\n\x0E" +
		"\n\xBB\v\n\x03\n\x03\n\x03\v\x03\v\x03\v\x03\v\x07\v\xC3\n\v\f\v\x0E\v" +
		"\xC6\v\v\x03\v\x03\v\x07\v\xCA\n\v\f\v\x0E\v\xCD\v\v\x03\v\x03\v\x03\f" +
		"\x03\f\x03\f\x03\f\x07\f\xD5\n\f\f\f\x0E\f\xD8\v\f\x03\f\x03\f\x07\f\xDC" +
		"\n\f\f\f\x0E\f\xDF\v\f\x03\f\x03\f\x03\r\x03\r\x03\x0E\x03\x0E\x03\x0F" +
		"\x03\x0F\x03\x10\x03\x10\x03\x11\x03\x11\x03\x12\x03\x12\x03\x13\x03\x13" +
		"\x03\x14\x03\x14\x03\x15\x03\x15\x03\x16\x03\x16\x03\x17\x03\x17\x03\x18" +
		"\x03\x18\x03\x19\x03\x19\x03\x1A\x03\x1A\x03\x1B\x03\x1B\x03\x1C\x03\x1C" +
		"\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x05\x1D\u0109\n\x1D\x03" +
		"\x1E\x03\x1E\x03\x1E\x03\x1E\x03\x1E\x03\x1E\x03\x1E\x03\x1E\x05\x1E\u0113" +
		"\n\x1E\x03\x1F\x03\x1F\x03\x1F\x03\x1F\x03\x1F\x03\x1F\x05\x1F\u011B\n" +
		"\x1F\x03 \x03 \x03 \x03!\x03!\x03!\x03\"\x03\"\x03#\x03#\x03$\x03$\x03" +
		"$\x03%\x03%\x03%\x03&\x03&\x03&\x03&\x05&\u0131\n&\x03\'\x03\'\x05\'\u0135" +
		"\n\'\x03\'\x06\'\u0138\n\'\r\'\x0E\'\u0139\x03\'\x03\'\x03(\x03(\x02\x02" +
		"\x02)\x03\x02\x03\x05\x02\x04\x07\x02\x05\t\x02\x06\v\x02\x07\r\x02\b" +
		"\x0F\x02\t\x11\x02\n\x13\x02\v\x15\x02\f\x17\x02\r\x19\x02\x02\x1B\x02" +
		"\x02\x1D\x02\x0E\x1F\x02\x0F!\x02\x10#\x02\x11%\x02\x12\'\x02\x13)\x02" +
		"\x14+\x02\x15-\x02\x16/\x02\x021\x02\x023\x02\x025\x02\x027\x02\x029\x02" +
		"\x17;\x02\x18=\x02\x19?\x02\x1AA\x02\x1BC\x02\x1CE\x02\x1DG\x02\x1EI\x02" +
		"\x1FK\x02 M\x02!O\x02\"\x03\x02\t\x03\x022;\x03\x02++\x03\x02__\x03\x02" +
		"\x7F\x7F\x03\x02$$\x03\x02\f\f\x04\x02\v\v\"\"\x02\u0154\x02\x03\x03\x02" +
		"\x02\x02\x02\x05\x03\x02\x02\x02\x02\x07\x03\x02\x02\x02\x02\t\x03\x02" +
		"\x02\x02\x02\v\x03\x02\x02\x02\x02\r\x03\x02\x02\x02\x02\x0F\x03\x02\x02" +
		"\x02\x02\x11\x03\x02\x02\x02\x02\x13\x03\x02\x02\x02\x02\x15\x03\x02\x02" +
		"\x02\x02\x17\x03\x02\x02\x02\x02\x1D\x03\x02\x02\x02\x02\x1F\x03\x02\x02" +
		"\x02\x02!\x03\x02\x02\x02\x02#\x03\x02\x02\x02\x02%\x03\x02\x02\x02\x02" +
		"\'\x03\x02\x02\x02\x02)\x03\x02\x02\x02\x02+\x03\x02\x02\x02\x02-\x03" +
		"\x02\x02\x02\x029\x03\x02\x02\x02\x02;\x03\x02\x02\x02\x02=\x03\x02\x02" +
		"\x02\x02?\x03\x02\x02\x02\x02A\x03\x02\x02\x02\x02C\x03\x02\x02\x02\x02" +
		"E\x03\x02\x02\x02\x02G\x03\x02\x02\x02\x02I\x03\x02\x02\x02\x02K\x03\x02" +
		"\x02\x02\x02M\x03\x02\x02\x02\x02O\x03\x02\x02\x02\x03Q\x03\x02\x02\x02" +
		"\x05U\x03\x02\x02\x02\x07X\x03\x02\x02\x02\tr\x03\x02\x02\x02\vu\x03\x02" +
		"\x02\x02\r\x92\x03\x02\x02\x02\x0F\x99\x03\x02\x02\x02\x11\xAA\x03\x02" +
		"\x02\x02\x13\xAC\x03\x02\x02\x02\x15\xBE\x03\x02\x02\x02\x17\xD0\x03\x02" +
		"\x02\x02\x19\xE2\x03\x02\x02\x02\x1B\xE4\x03\x02\x02\x02\x1D\xE6\x03\x02" +
		"\x02\x02\x1F\xE8\x03\x02\x02\x02!\xEA\x03\x02\x02\x02#\xEC\x03\x02\x02" +
		"\x02%\xEE\x03\x02\x02\x02\'\xF0\x03\x02\x02\x02)\xF2\x03\x02\x02\x02+" +
		"\xF4\x03\x02\x02\x02-\xF6\x03\x02\x02\x02/\xF8\x03\x02\x02\x021\xFA\x03" +
		"\x02\x02\x023\xFC\x03\x02\x02\x025\xFE\x03\x02\x02\x027\u0100\x03\x02" +
		"\x02\x029\u0108\x03\x02\x02\x02;\u0112\x03\x02\x02\x02=\u011A\x03\x02" +
		"\x02\x02?\u011C\x03\x02\x02\x02A\u011F\x03\x02\x02\x02C\u0122\x03\x02" +
		"\x02\x02E\u0124\x03\x02\x02\x02G\u0126\x03\x02\x02\x02I\u0129\x03\x02" +
		"\x02\x02K\u0130\x03\x02\x02\x02M\u0137\x03\x02\x02\x02O\u013D\x03\x02" +
		"\x02\x02QR\x07m\x02\x02RS\x07g\x02\x02ST\x07{\x02\x02T\x04\x03\x02\x02" +
		"\x02UV\x05\x03\x02\x02VW\x05\x07\x04\x02W\x06\x03\x02\x02\x02XZ\x05\'" +
		"\x14\x02Y[\x051\x19\x02ZY\x03\x02\x02\x02[\\\x03\x02\x02\x02\\Z\x03\x02" +
		"\x02\x02\\]\x03\x02\x02\x02]^\x03\x02\x02\x02^_\x05)\x15\x02_\b\x03\x02" +
		"\x02\x02`a\x07V\x02\x02ab\x07T\x02\x02bc\x07W\x02\x02cs\x07G\x02\x02d" +
		"e\x07v\x02\x02ef\x07t\x02\x02fg\x07w\x02\x02gs\x07g\x02\x02hi\x07H\x02" +
		"\x02ij\x07C\x02\x02jk\x07N\x02\x02kl\x07U\x02\x02ls\x07G\x02\x02mn\x07" +
		"h\x02\x02no\x07c\x02\x02op\x07n\x02\x02pq\x07u\x02\x02qs\x07g\x02\x02" +
		"r`\x03\x02\x02\x02rd\x03\x02\x02\x02rh\x03\x02\x02\x02rm\x03\x02\x02\x02" +
		"s\n\x03\x02\x02\x02tv\x05\x1B\x0E\x02ut\x03\x02\x02\x02uv\x03\x02\x02" +
		"\x02v\x8F\x03\x02\x02\x02wy\x05\x19\r\x02xw\x03\x02\x02\x02yz\x03\x02" +
		"\x02\x02zx\x03\x02\x02\x02z{\x03\x02\x02\x02{|\x03\x02\x02\x02|\x80\x05" +
		"\x1D\x0F\x02}\x7F\x05\x19\r\x02~}\x03\x02\x02\x02\x7F\x82\x03\x02\x02" +
		"\x02\x80~\x03\x02\x02\x02\x80\x81\x03\x02\x02\x02\x81\x90\x03\x02\x02" +
		"\x02\x82\x80\x03\x02\x02\x02\x83\x85\x05\x19\r\x02\x84\x83\x03\x02\x02" +
		"\x02\x85\x88\x03\x02\x02\x02\x86\x84\x03\x02\x02\x02\x86\x87\x03\x02\x02" +
		"\x02\x87\x89\x03\x02\x02\x02\x88\x86\x03\x02\x02\x02\x89\x8B\x05\x1D\x0F" +
		"\x02\x8A\x8C\x05\x19\r\x02\x8B\x8A\x03\x02\x02\x02\x8C\x8D\x03\x02\x02" +
		"\x02\x8D\x8B\x03\x02\x02\x02\x8D\x8E\x03\x02\x02\x02\x8E\x90\x03\x02\x02" +
		"\x02\x8Fx\x03\x02\x02\x02\x8F\x86\x03\x02\x02\x02\x90\f\x03\x02\x02\x02" +
		"\x91\x93\x05\x1B\x0E\x02\x92\x91\x03\x02\x02\x02\x92\x93\x03\x02\x02\x02" +
		"\x93\x95\x03\x02\x02\x02\x94\x96\x05\x19\r\x02\x95\x94\x03\x02\x02\x02" +
		"\x96\x97\x03\x02\x02\x02\x97\x95\x03\x02\x02\x02\x97\x98\x03\x02\x02\x02" +
		"\x98\x0E\x03\x02\x02\x02\x99\x9D\x05!\x11\x02\x9A\x9C\x055\x1B\x02\x9B" +
		"\x9A\x03\x02\x02\x02\x9C\x9F\x03\x02\x02\x02\x9D\x9B\x03\x02\x02\x02\x9D" +
		"\x9E\x03\x02\x02\x02\x9E\xA0\x03\x02\x02\x02\x9F\x9D\x03\x02\x02\x02\xA0" +
		"\xA1\x05!\x11\x02\xA1\x10\x03\x02\x02\x02\xA2\xA3\x07P\x02\x02\xA3\xA4" +
		"\x07W\x02\x02\xA4\xA5\x07N\x02\x02\xA5\xAB\x07N\x02\x02\xA6\xA7\x07p\x02" +
		"\x02\xA7\xA8\x07w\x02\x02\xA8\xA9\x07n\x02\x02\xA9\xAB\x07n\x02\x02\xAA" +
		"\xA2\x03\x02\x02\x02\xAA\xA6\x03\x02\x02\x02\xAB\x12\x03\x02\x02\x02\xAC" +
		"\xAD\x05+\x16\x02\xAD\xB9\x05\v\x06\x02\xAE\xB2\x05\x1F\x10\x02\xAF\xB1" +
		"\x05M\'\x02\xB0\xAF\x03\x02\x02\x02\xB1\xB4\x03\x02\x02\x02\xB2\xB0\x03" +
		"\x02\x02\x02\xB2\xB3\x03\x02\x02\x02\xB3\xB5\x03\x02\x02\x02\xB4\xB2\x03" +
		"\x02\x02\x02\xB5\xB6\x05\v\x06\x02\xB6\xB8\x03\x02\x02\x02\xB7\xAE\x03" +
		"\x02\x02\x02\xB8\xBB\x03\x02\x02\x02\xB9\xB7\x03\x02\x02\x02\xB9\xBA\x03" +
		"\x02\x02\x02\xBA\xBC\x03\x02\x02\x02\xBB\xB9\x03\x02\x02\x02\xBC\xBD\x05" +
		"-\x17\x02\xBD\x14\x03\x02\x02\x02\xBE\xBF\x05+\x16\x02\xBF\xCB\x05\r\x07" +
		"\x02\xC0\xC4\x05\x1F\x10\x02\xC1\xC3\x05M\'\x02\xC2\xC1\x03\x02\x02\x02" +
		"\xC3\xC6\x03\x02\x02\x02\xC4\xC2\x03\x02\x02\x02\xC4\xC5\x03\x02\x02\x02" +
		"\xC5\xC7\x03\x02\x02\x02\xC6\xC4\x03\x02\x02\x02\xC7\xC8\x05\r\x07\x02" +
		"\xC8\xCA\x03\x02\x02\x02\xC9\xC0\x03\x02\x02\x02\xCA\xCD\x03\x02\x02\x02" +
		"\xCB\xC9\x03\x02\x02\x02\xCB\xCC\x03\x02\x02\x02\xCC\xCE\x03\x02\x02\x02" +
		"\xCD\xCB\x03\x02\x02\x02\xCE\xCF\x05-\x17\x02\xCF\x16\x03\x02\x02\x02" +
		"\xD0\xD1\x05+\x16\x02\xD1\xDD\x05\x0F\b\x02\xD2\xD6\x05\x1F\x10\x02\xD3" +
		"\xD5\x05M\'\x02\xD4\xD3\x03\x02\x02\x02\xD5\xD8\x03\x02\x02\x02\xD6\xD4" +
		"\x03\x02\x02\x02\xD6\xD7\x03\x02\x02\x02\xD7\xD9\x03\x02\x02\x02\xD8\xD6" +
		"\x03\x02\x02\x02\xD9\xDA\x05\x0F\b\x02\xDA\xDC\x03\x02\x02\x02\xDB\xD2" +
		"\x03\x02\x02\x02\xDC\xDF\x03\x02\x02\x02\xDD\xDB\x03\x02\x02\x02\xDD\xDE" +
		"\x03\x02\x02\x02\xDE\xE0\x03\x02\x02\x02\xDF\xDD\x03\x02\x02\x02\xE0\xE1" +
		"\x05-\x17\x02\xE1\x18\x03\x02\x02\x02\xE2\xE3\t\x02\x02\x02\xE3\x1A\x03" +
		"\x02\x02\x02\xE4\xE5\x07/\x02\x02\xE5\x1C\x03\x02\x02\x02\xE6\xE7\x07" +
		"0\x02\x02\xE7\x1E\x03\x02\x02\x02\xE8\xE9\x07.\x02\x02\xE9 \x03\x02\x02" +
		"\x02\xEA\xEB\x07$\x02\x02\xEB\"\x03\x02\x02\x02\xEC\xED\x07*\x02\x02\xED" +
		"$\x03\x02\x02\x02\xEE\xEF\x07+\x02\x02\xEF&\x03\x02\x02\x02\xF0\xF1\x07" +
		"]\x02\x02\xF1(\x03\x02\x02\x02\xF2\xF3\x07_\x02\x02\xF3*\x03\x02\x02\x02" +
		"\xF4\xF5\x07}\x02\x02\xF5,\x03\x02\x02\x02\xF6\xF7\x07\x7F\x02\x02\xF7" +
		".\x03\x02\x02\x02\xF8\xF9\n\x03\x02\x02\xF90\x03\x02\x02\x02\xFA\xFB\n" +
		"\x04\x02\x02\xFB2\x03\x02\x02\x02\xFC\xFD\n\x05\x02\x02\xFD4\x03\x02\x02" +
		"\x02\xFE\xFF\n\x06\x02\x02\xFF6\x03\x02\x02\x02\u0100\u0101\n\x07\x02" +
		"\x02\u01018\x03\x02\x02\x02\u0102\u0103\x07P\x02\x02\u0103\u0104\x07Q" +
		"\x02\x02\u0104\u0109\x07V\x02\x02\u0105\u0106\x07p\x02\x02\u0106\u0107" +
		"\x07q\x02\x02\u0107\u0109\x07v\x02\x02\u0108\u0102\x03\x02\x02\x02\u0108" +
		"\u0105\x03\x02\x02\x02\u0109:\x03\x02\x02\x02\u010A\u010B\x07C\x02\x02" +
		"\u010B\u010C\x07P\x02\x02\u010C\u0113\x07F\x02\x02\u010D\u010E\x07c\x02" +
		"\x02\u010E\u010F\x07p\x02\x02\u010F\u0113\x07f\x02\x02\u0110\u0111\x07" +
		"(\x02\x02\u0111\u0113\x07(\x02\x02\u0112\u010A\x03\x02\x02\x02\u0112\u010D" +
		"\x03\x02\x02\x02\u0112\u0110\x03\x02\x02\x02\u0113<\x03\x02\x02\x02\u0114" +
		"\u0115\x07Q\x02\x02\u0115\u011B\x07T\x02\x02\u0116\u0117\x07q\x02\x02" +
		"\u0117\u011B\x07t\x02\x02\u0118\u0119\x07~\x02\x02\u0119\u011B\x07~\x02" +
		"\x02\u011A\u0114\x03\x02\x02\x02\u011A\u0116\x03\x02\x02\x02\u011A\u0118" +
		"\x03\x02\x02\x02\u011B>\x03\x02\x02\x02\u011C\u011D\x07?\x02\x02\u011D" +
		"\u011E\x07?\x02\x02\u011E@\x03\x02\x02\x02\u011F\u0120\x07#\x02\x02\u0120" +
		"\u0121\x07?\x02\x02\u0121B\x03\x02\x02\x02\u0122\u0123\x07>\x02\x02\u0123" +
		"D\x03\x02\x02\x02\u0124\u0125\x07@\x02\x02\u0125F\x03\x02\x02\x02\u0126" +
		"\u0127\x07>\x02\x02\u0127\u0128\x07?\x02\x02\u0128H\x03\x02\x02\x02\u0129" +
		"\u012A\x07@\x02\x02\u012A\u012B\x07?\x02\x02\u012BJ\x03\x02\x02\x02\u012C" +
		"\u012D\x07K\x02\x02\u012D\u0131\x07P\x02\x02\u012E\u012F\x07k\x02\x02" +
		"\u012F\u0131\x07p\x02\x02\u0130\u012C\x03\x02\x02\x02\u0130\u012E\x03" +
		"\x02\x02\x02\u0131L\x03\x02\x02\x02\u0132\u0138\t\b\x02\x02\u0133\u0135" +
		"\x07\x0F\x02\x02\u0134\u0133\x03\x02\x02\x02\u0134\u0135\x03\x02\x02\x02" +
		"\u0135\u0136\x03\x02\x02\x02\u0136\u0138\x07\f\x02\x02\u0137\u0132\x03" +
		"\x02\x02\x02\u0137\u0134\x03\x02\x02\x02\u0138\u0139\x03\x02\x02\x02\u0139" +
		"\u0137\x03\x02\x02\x02\u0139\u013A\x03\x02\x02\x02\u013A\u013B\x03\x02" +
		"\x02\x02\u013B\u013C\b\'\x02\x02\u013CN\x03\x02\x02\x02\u013D\u013E\v" +
		"\x02\x02\x02\u013EP\x03\x02\x02\x02\x1C\x02\\ruz\x80\x86\x8D\x8F\x92\x97" +
		"\x9D\xAA\xB2\xB9\xC4\xCB\xD6\xDD\u0108\u0112\u011A\u0130\u0134\u0137\u0139" +
		"\x03\b\x02\x02";
	public static __ATN: ATN;
	public static get _ATN(): ATN {
		if (!FoliaLexer.__ATN) {
			FoliaLexer.__ATN = new ATNDeserializer().deserialize(Utils.toCharArray(FoliaLexer._serializedATN));
		}

		return FoliaLexer.__ATN;
	}

}

