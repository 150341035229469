import axios from 'axios';
import {
  CustomGroupingDto,
  GroupingNode,
  transformFromDto,
} from '../../../model/custom-grouping/customGrouping';
import { isPredicateGroupingStrategy } from '../../../model/custom-grouping/groupingStrategy';
import { WorkspaceFilter } from '../../../shared/dataTypes';
import { baseUrl } from '../../shared/environment';

export type WhereClauseToken =
  | { type: 'KEY_EXPRESSION'; text: string }
  | { type: 'COMP_EQ'; text: '==' }
  | { type: 'STRING_LITERAL'; text: string }
  | { type: 'EOL'; text: null }
  | { type: 'EOF'; text: null }
  | { type: 'L_PAREN'; text: '(' }
  | { type: 'R_PAREN'; text: ')' }
  | { type: 'LOGICAL_AND'; text: 'and' }
  | { type: 'LOGICAL_OR'; text: 'or' };

export const generateTokenArray = (
  node: GroupingNode,
  selectedNodeIds: string[],
): WhereClauseToken[] => {
  // If there are no selected nodes, there's no where clause. Also if node is neither root node
  // (groupingStrategy === null) nor predicate, return null.
  if (
    !selectedNodeIds?.length ||
    (node.groupingStrategy && !isPredicateGroupingStrategy(node.groupingStrategy))
  )
    return [];

  if (node.groupingStrategy === null) {
    // Root node
    return joinSelectedChildrenWithOr(node, selectedNodeIds);
  }

  if (isPredicateGroupingStrategy(node.groupingStrategy)) {
    // Always true - conditional exists for TS purposes

    if (!node.children?.length) {
      return node.groupingStrategy.predicate.tokens as WhereClauseToken[];
    } else
      return [
        { type: 'L_PAREN', text: '(' },
        ...node.groupingStrategy.predicate.tokens,
        { type: 'R_PAREN', text: ')' },
        { type: 'LOGICAL_AND', text: 'and' },
        { type: 'L_PAREN', text: '(' },
        ...joinSelectedChildrenWithOr(node, selectedNodeIds),
        { type: 'R_PAREN', text: ')' },
      ] as WhereClauseToken[]; // TODO improve typing between WhereClauseToken and SimpleToken
  } else {
    return [];
  }
};

const joinSelectedChildrenWithOr = (
  node: GroupingNode,
  selectedNodeIds: string[],
): WhereClauseToken[] => {
  const tokensWithoutOr = node.children
    .filter(c => descendentsSelected(c, selectedNodeIds))
    .flatMap(c => generateTokenArray(c, selectedNodeIds))
    .filter(c => !['EOL', 'EOF'].includes(c?.type));

  return tokensWithoutOr.reduce<WhereClauseToken[]>(
    (acc, cur, i) => [
      ...acc,
      cur,
      ...((cur.type === 'STRING_LITERAL' && tokensWithoutOr[i + 1]?.type === 'KEY_EXPRESSION') ||
      (cur.type === 'R_PAREN' &&
        ['L_PAREN', 'KEY_EXPRESSION'].includes(tokensWithoutOr[i + 1]?.type))
        ? [{ type: 'LOGICAL_OR', text: 'or' } as const]
        : []),
    ],
    [],
  );
};

const descendentsSelected = (node: GroupingNode, selectedNodeIds: string[]): boolean =>
  selectedNodeIds.includes(node.id) ||
  node.children?.some(c => descendentsSelected(c, selectedNodeIds));

export const getTokenArray = async (filter: WorkspaceFilter): Promise<WhereClauseToken[]> => {
  if (!filter?.customGroupingId) return [];

  const { data } = await axios.get<CustomGroupingDto>(`${baseUrl}api/customGrouping`, {
    params: { id: filter.customGroupingId },
  });

  return generateTokenArray(transformFromDto(data).rootNode, filter.selectedNodeIds ?? []);
};

export const workspaceFiltersEqual = (a: WorkspaceFilter, b: WorkspaceFilter) =>
  (!a && !b) ||
  (!a?.customGroupingId && !b?.customGroupingId) ||
  (a?.customGroupingId === b?.customGroupingId &&
    ((!a.selectedNodeIds && !b.selectedNodeIds) ||
      (a.selectedNodeIds?.every(n => b.selectedNodeIds?.includes(n)) &&
        b.selectedNodeIds?.length === a.selectedNodeIds.length)));
