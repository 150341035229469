import { StyleRule } from './styleRule';

export interface ReportStyleData {
  //uniform style might not be the best name anymore, but changing it would require backwards compatible work
  uniformStyles: StyleRule[];
}

export const createTrivialReportStyleData = (): ReportStyleData => ({
  uniformStyles: [],
});
