import { FC } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import MainComponent from '../main/Main';
import { isOidcClientEnabled, isPsbcPortalEnabled } from '../shared/environment';
import AuthProvider from './auth/AuthProvider';
import OidcRoutes from './routing/OidcRoutes';
import PsbcRoutes from './routing/PsbcRoutes';

const SecuritySelector: FC = () => (
  <BrowserRouter basename='/'>
    <AuthProvider>
      {isOidcClientEnabled ? (
        <OidcRoutes />
      ) : isPsbcPortalEnabled ? (
        <PsbcRoutes />
      ) : (
        <Route render={() => <MainComponent oidcLogout={() => {}} psbcLogout={() => {}} />} />
      )}
    </AuthProvider>
  </BrowserRouter>
);

export default SecuritySelector;
