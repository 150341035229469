import { Reducer } from '@reduxjs/toolkit';
import { AScriptEditorAction, AScriptEditorActionType } from './actions';
import { AScriptEditorState, createDefaultState } from './state';

const reducer: Reducer<AScriptEditorState, AScriptEditorAction> = (
  state = createDefaultState(),
  action,
) => {
  switch (action.type) {
    case AScriptEditorActionType.OPEN: {
      return {
        ...action.payload,
      };
    }

    case AScriptEditorActionType.CLOSE: {
      return createDefaultState();
    }

    case AScriptEditorActionType.SET_SUPERCLASS_NAME: {
      return {
        ...state,
        superclassName: action.payload,
      };
    }

    case AScriptEditorActionType.SET_CLASS_NAME: {
      return {
        ...state,
        className: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
