// cf. `integratedrisk.common.util.Constants.PA_METHOD_*`
export enum ReturnMethodology {
  TTWR = 'TTWR',
  MODIFIED_DIETZ = 'Modified Dietz',
  IRR = 'IRR',
}

export interface Performance {
  periodStartDate: string;
  returnMethodology: ReturnMethodology;
}

export const createDefaultPerformance = (): Performance => ({
  periodStartDate: '',
  returnMethodology: ReturnMethodology.TTWR,
});
