import { Reducer } from '@reduxjs/toolkit';
import { PositionDrawerAction, PositionDrawerActionType } from './actions';
import { createDefaultState, PositionDrawerState } from './state';

const reducer: Reducer<PositionDrawerState, PositionDrawerAction> = (
  state = createDefaultState(),
  action,
) => {
  switch (action.type) {
    case PositionDrawerActionType.OPEN: {
      const { sandbox, sequenceId, mode, positionToEdit } = action.payload;
      return { ...state, open: true, sandbox, sequenceId, mode, positionToEdit };
    }
    case PositionDrawerActionType.CLOSE: {
      return { open: false };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
