// Generated from src/lang/folia/FoliaParser.g4 by ANTLR 4.9.0-SNAPSHOT


import { ATN } from "antlr4ts/atn/ATN";
import { ATNDeserializer } from "antlr4ts/atn/ATNDeserializer";
import { FailedPredicateException } from "antlr4ts/FailedPredicateException";
import { NotNull } from "antlr4ts/Decorators";
import { NoViableAltException } from "antlr4ts/NoViableAltException";
import { Override } from "antlr4ts/Decorators";
import { Parser } from "antlr4ts/Parser";
import { ParserRuleContext } from "antlr4ts/ParserRuleContext";
import { ParserATNSimulator } from "antlr4ts/atn/ParserATNSimulator";
import { ParseTreeListener } from "antlr4ts/tree/ParseTreeListener";
import { ParseTreeVisitor } from "antlr4ts/tree/ParseTreeVisitor";
import { RecognitionException } from "antlr4ts/RecognitionException";
import { RuleContext } from "antlr4ts/RuleContext";
//import { RuleVersion } from "antlr4ts/RuleVersion";
import { TerminalNode } from "antlr4ts/tree/TerminalNode";
import { Token } from "antlr4ts/Token";
import { TokenStream } from "antlr4ts/TokenStream";
import { Vocabulary } from "antlr4ts/Vocabulary";
import { VocabularyImpl } from "antlr4ts/VocabularyImpl";

import * as Utils from "antlr4ts/misc/Utils";

import { FoliaParserListener } from "./FoliaParserListener";
import { FoliaParserVisitor } from "./FoliaParserVisitor";


export class FoliaParser extends Parser {
	public static readonly KW_KEY = 1;
	public static readonly KEY_EXPRESSION = 2;
	public static readonly KEY_ACCESSOR = 3;
	public static readonly BOOLEAN_LITERAL = 4;
	public static readonly DOUBLE_LITERAL = 5;
	public static readonly INT_LITERAL = 6;
	public static readonly STRING_LITERAL = 7;
	public static readonly NULL_LITERAL = 8;
	public static readonly DOUBLE_ARRAY_LITERAL = 9;
	public static readonly INT_ARRAY_LITERAL = 10;
	public static readonly STRING_ARRAY_LITERAL = 11;
	public static readonly PERIOD = 12;
	public static readonly COMMA = 13;
	public static readonly DOUBLE_QUOTE = 14;
	public static readonly L_PAREN = 15;
	public static readonly R_PAREN = 16;
	public static readonly L_BRACKET = 17;
	public static readonly R_BRACKET = 18;
	public static readonly L_BRACE = 19;
	public static readonly R_BRACE = 20;
	public static readonly LOGICAL_NOT = 21;
	public static readonly LOGICAL_AND = 22;
	public static readonly LOGICAL_OR = 23;
	public static readonly COMP_EQ = 24;
	public static readonly COMP_NEQ = 25;
	public static readonly COMP_LT = 26;
	public static readonly COMP_GT = 27;
	public static readonly COMP_LEQ = 28;
	public static readonly COMP_GEQ = 29;
	public static readonly COMP_IN = 30;
	public static readonly WS = 31;
	public static readonly UNRECOGNIZED = 32;
	public static readonly RULE_compilationUnit = 0;
	public static readonly RULE_expression = 1;
	public static readonly RULE_relationExpression = 2;
	public static readonly RULE_atomicExpression = 3;
	public static readonly RULE_primitiveExpression = 4;
	public static readonly RULE_arrayExpression = 5;
	// tslint:disable:no-trailing-whitespace
	public static readonly ruleNames: string[] = [
		"compilationUnit", "expression", "relationExpression", "atomicExpression", 
		"primitiveExpression", "arrayExpression",
	];

	private static readonly _LITERAL_NAMES: Array<string | undefined> = [
		undefined, "'key'", undefined, undefined, undefined, undefined, undefined, 
		undefined, undefined, undefined, undefined, undefined, "'.'", "','", "'\"'", 
		"'('", "')'", "'['", "']'", "'{'", "'}'", undefined, undefined, undefined, 
		"'=='", "'!='", "'<'", "'>'", "'<='", "'>='",
	];
	private static readonly _SYMBOLIC_NAMES: Array<string | undefined> = [
		undefined, "KW_KEY", "KEY_EXPRESSION", "KEY_ACCESSOR", "BOOLEAN_LITERAL", 
		"DOUBLE_LITERAL", "INT_LITERAL", "STRING_LITERAL", "NULL_LITERAL", "DOUBLE_ARRAY_LITERAL", 
		"INT_ARRAY_LITERAL", "STRING_ARRAY_LITERAL", "PERIOD", "COMMA", "DOUBLE_QUOTE", 
		"L_PAREN", "R_PAREN", "L_BRACKET", "R_BRACKET", "L_BRACE", "R_BRACE", 
		"LOGICAL_NOT", "LOGICAL_AND", "LOGICAL_OR", "COMP_EQ", "COMP_NEQ", "COMP_LT", 
		"COMP_GT", "COMP_LEQ", "COMP_GEQ", "COMP_IN", "WS", "UNRECOGNIZED",
	];
	public static readonly VOCABULARY: Vocabulary = new VocabularyImpl(FoliaParser._LITERAL_NAMES, FoliaParser._SYMBOLIC_NAMES, []);

	// @Override
	// @NotNull
	public get vocabulary(): Vocabulary {
		return FoliaParser.VOCABULARY;
	}
	// tslint:enable:no-trailing-whitespace

	// @Override
	public get grammarFileName(): string { return "FoliaParser.g4"; }

	// @Override
	public get ruleNames(): string[] { return FoliaParser.ruleNames; }

	// @Override
	public get serializedATN(): string { return FoliaParser._serializedATN; }

	protected createFailedPredicateException(predicate?: string, message?: string): FailedPredicateException {
		return new FailedPredicateException(this, predicate, message);
	}

	constructor(input: TokenStream) {
		super(input);
		this._interp = new ParserATNSimulator(FoliaParser._ATN, this);
	}
	// @RuleVersion(0)
	public compilationUnit(): CompilationUnitContext {
		let _localctx: CompilationUnitContext = new CompilationUnitContext(this._ctx, this.state);
		this.enterRule(_localctx, 0, FoliaParser.RULE_compilationUnit);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 12;
			this.expression(0);
			this.state = 13;
			this.match(FoliaParser.EOF);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}

	public expression(): ExpressionContext;
	public expression(_p: number): ExpressionContext;
	// @RuleVersion(0)
	public expression(_p?: number): ExpressionContext {
		if (_p === undefined) {
			_p = 0;
		}

		let _parentctx: ParserRuleContext = this._ctx;
		let _parentState: number = this.state;
		let _localctx: ExpressionContext = new ExpressionContext(this._ctx, _parentState);
		let _prevctx: ExpressionContext = _localctx;
		let _startState: number = 2;
		this.enterRecursionRule(_localctx, 2, FoliaParser.RULE_expression, _p);
		try {
			let _alt: number;
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 23;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case FoliaParser.L_PAREN:
				{
				this.state = 16;
				this.match(FoliaParser.L_PAREN);
				this.state = 17;
				this.expression(0);
				this.state = 18;
				this.match(FoliaParser.R_PAREN);
				}
				break;
			case FoliaParser.LOGICAL_NOT:
				{
				this.state = 20;
				this.match(FoliaParser.LOGICAL_NOT);
				this.state = 21;
				this.expression(4);
				}
				break;
			case FoliaParser.KEY_EXPRESSION:
			case FoliaParser.BOOLEAN_LITERAL:
			case FoliaParser.DOUBLE_LITERAL:
			case FoliaParser.INT_LITERAL:
			case FoliaParser.STRING_LITERAL:
			case FoliaParser.NULL_LITERAL:
				{
				this.state = 22;
				this.relationExpression();
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
			this._ctx._stop = this._input.tryLT(-1);
			this.state = 33;
			this._errHandler.sync(this);
			_alt = this.interpreter.adaptivePredict(this._input, 2, this._ctx);
			while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER) {
				if (_alt === 1) {
					if (this._parseListeners != null) {
						this.triggerExitRuleEvent();
					}
					_prevctx = _localctx;
					{
					this.state = 31;
					this._errHandler.sync(this);
					switch ( this.interpreter.adaptivePredict(this._input, 1, this._ctx) ) {
					case 1:
						{
						_localctx = new ExpressionContext(_parentctx, _parentState);
						this.pushNewRecursionContext(_localctx, _startState, FoliaParser.RULE_expression);
						this.state = 25;
						if (!(this.precpred(this._ctx, 3))) {
							throw this.createFailedPredicateException("this.precpred(this._ctx, 3)");
						}
						this.state = 26;
						this.match(FoliaParser.LOGICAL_AND);
						this.state = 27;
						this.expression(4);
						}
						break;

					case 2:
						{
						_localctx = new ExpressionContext(_parentctx, _parentState);
						this.pushNewRecursionContext(_localctx, _startState, FoliaParser.RULE_expression);
						this.state = 28;
						if (!(this.precpred(this._ctx, 2))) {
							throw this.createFailedPredicateException("this.precpred(this._ctx, 2)");
						}
						this.state = 29;
						this.match(FoliaParser.LOGICAL_OR);
						this.state = 30;
						this.expression(3);
						}
						break;
					}
					}
				}
				this.state = 35;
				this._errHandler.sync(this);
				_alt = this.interpreter.adaptivePredict(this._input, 2, this._ctx);
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.unrollRecursionContexts(_parentctx);
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public relationExpression(): RelationExpressionContext {
		let _localctx: RelationExpressionContext = new RelationExpressionContext(this._ctx, this.state);
		this.enterRule(_localctx, 4, FoliaParser.RULE_relationExpression);
		try {
			this.state = 64;
			this._errHandler.sync(this);
			switch ( this.interpreter.adaptivePredict(this._input, 3, this._ctx) ) {
			case 1:
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 36;
				this.atomicExpression();
				this.state = 37;
				this.match(FoliaParser.COMP_EQ);
				this.state = 38;
				this.atomicExpression();
				}
				break;

			case 2:
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 40;
				this.atomicExpression();
				this.state = 41;
				this.match(FoliaParser.COMP_NEQ);
				this.state = 42;
				this.atomicExpression();
				}
				break;

			case 3:
				this.enterOuterAlt(_localctx, 3);
				{
				this.state = 44;
				this.atomicExpression();
				this.state = 45;
				this.match(FoliaParser.COMP_LT);
				this.state = 46;
				this.atomicExpression();
				}
				break;

			case 4:
				this.enterOuterAlt(_localctx, 4);
				{
				this.state = 48;
				this.atomicExpression();
				this.state = 49;
				this.match(FoliaParser.COMP_GT);
				this.state = 50;
				this.atomicExpression();
				}
				break;

			case 5:
				this.enterOuterAlt(_localctx, 5);
				{
				this.state = 52;
				this.atomicExpression();
				this.state = 53;
				this.match(FoliaParser.COMP_LEQ);
				this.state = 54;
				this.atomicExpression();
				}
				break;

			case 6:
				this.enterOuterAlt(_localctx, 6);
				{
				this.state = 56;
				this.atomicExpression();
				this.state = 57;
				this.match(FoliaParser.COMP_GEQ);
				this.state = 58;
				this.atomicExpression();
				}
				break;

			case 7:
				this.enterOuterAlt(_localctx, 7);
				{
				this.state = 60;
				this.atomicExpression();
				this.state = 61;
				this.match(FoliaParser.COMP_IN);
				this.state = 62;
				this.arrayExpression();
				}
				break;
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public atomicExpression(): AtomicExpressionContext {
		let _localctx: AtomicExpressionContext = new AtomicExpressionContext(this._ctx, this.state);
		this.enterRule(_localctx, 6, FoliaParser.RULE_atomicExpression);
		try {
			this.state = 68;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case FoliaParser.KEY_EXPRESSION:
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 66;
				this.match(FoliaParser.KEY_EXPRESSION);
				}
				break;
			case FoliaParser.BOOLEAN_LITERAL:
			case FoliaParser.DOUBLE_LITERAL:
			case FoliaParser.INT_LITERAL:
			case FoliaParser.STRING_LITERAL:
			case FoliaParser.NULL_LITERAL:
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 67;
				this.primitiveExpression();
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public primitiveExpression(): PrimitiveExpressionContext {
		let _localctx: PrimitiveExpressionContext = new PrimitiveExpressionContext(this._ctx, this.state);
		this.enterRule(_localctx, 8, FoliaParser.RULE_primitiveExpression);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 70;
			_la = this._input.LA(1);
			if (!((((_la) & ~0x1F) === 0 && ((1 << _la) & ((1 << FoliaParser.BOOLEAN_LITERAL) | (1 << FoliaParser.DOUBLE_LITERAL) | (1 << FoliaParser.INT_LITERAL) | (1 << FoliaParser.STRING_LITERAL) | (1 << FoliaParser.NULL_LITERAL))) !== 0))) {
			this._errHandler.recoverInline(this);
			} else {
				if (this._input.LA(1) === Token.EOF) {
					this.matchedEOF = true;
				}

				this._errHandler.reportMatch(this);
				this.consume();
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public arrayExpression(): ArrayExpressionContext {
		let _localctx: ArrayExpressionContext = new ArrayExpressionContext(this._ctx, this.state);
		this.enterRule(_localctx, 10, FoliaParser.RULE_arrayExpression);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 72;
			_la = this._input.LA(1);
			if (!((((_la) & ~0x1F) === 0 && ((1 << _la) & ((1 << FoliaParser.DOUBLE_ARRAY_LITERAL) | (1 << FoliaParser.INT_ARRAY_LITERAL) | (1 << FoliaParser.STRING_ARRAY_LITERAL))) !== 0))) {
			this._errHandler.recoverInline(this);
			} else {
				if (this._input.LA(1) === Token.EOF) {
					this.matchedEOF = true;
				}

				this._errHandler.reportMatch(this);
				this.consume();
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}

	public sempred(_localctx: RuleContext, ruleIndex: number, predIndex: number): boolean {
		switch (ruleIndex) {
		case 1:
			return this.expression_sempred(_localctx as ExpressionContext, predIndex);
		}
		return true;
	}
	private expression_sempred(_localctx: ExpressionContext, predIndex: number): boolean {
		switch (predIndex) {
		case 0:
			return this.precpred(this._ctx, 3);

		case 1:
			return this.precpred(this._ctx, 2);
		}
		return true;
	}

	public static readonly _serializedATN: string =
		"\x03\uC91D\uCABA\u058D\uAFBA\u4F53\u0607\uEA8B\uC241\x03\"M\x04\x02\t" +
		"\x02\x04\x03\t\x03\x04\x04\t\x04\x04\x05\t\x05\x04\x06\t\x06\x04\x07\t" +
		"\x07\x03\x02\x03\x02\x03\x02\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03" +
		"\x03\x03\x03\x03\x03\x05\x03\x1A\n\x03\x03\x03\x03\x03\x03\x03\x03\x03" +
		"\x03\x03\x03\x03\x07\x03\"\n\x03\f\x03\x0E\x03%\v\x03\x03\x04\x03\x04" +
		"\x03\x04\x03\x04\x03\x04\x03\x04\x03\x04\x03\x04\x03\x04\x03\x04\x03\x04" +
		"\x03\x04\x03\x04\x03\x04\x03\x04\x03\x04\x03\x04\x03\x04\x03\x04\x03\x04" +
		"\x03\x04\x03\x04\x03\x04\x03\x04\x03\x04\x03\x04\x03\x04\x03\x04\x05\x04" +
		"C\n\x04\x03\x05\x03\x05\x05\x05G\n\x05\x03\x06\x03\x06\x03\x07\x03\x07" +
		"\x03\x07\x02\x02\x03\x04\b\x02\x02\x04\x02\x06\x02\b\x02\n\x02\f\x02\x02" +
		"\x04\x03\x02\x06\n\x03\x02\v\r\x02Q\x02\x0E\x03\x02\x02\x02\x04\x19\x03" +
		"\x02\x02\x02\x06B\x03\x02\x02\x02\bF\x03\x02\x02\x02\nH\x03\x02\x02\x02" +
		"\fJ\x03\x02\x02\x02\x0E\x0F\x05\x04\x03\x02\x0F\x10\x07\x02\x02\x03\x10" +
		"\x03\x03\x02\x02\x02\x11\x12\b\x03\x01\x02\x12\x13\x07\x11\x02\x02\x13" +
		"\x14\x05\x04\x03\x02\x14\x15\x07\x12\x02\x02\x15\x1A\x03\x02\x02\x02\x16" +
		"\x17\x07\x17\x02\x02\x17\x1A\x05\x04\x03\x06\x18\x1A\x05\x06\x04\x02\x19" +
		"\x11\x03\x02\x02\x02\x19\x16\x03\x02\x02\x02\x19\x18\x03\x02\x02\x02\x1A" +
		"#\x03\x02\x02\x02\x1B\x1C\f\x05\x02\x02\x1C\x1D\x07\x18\x02\x02\x1D\"" +
		"\x05\x04\x03\x06\x1E\x1F\f\x04\x02\x02\x1F \x07\x19\x02\x02 \"\x05\x04" +
		"\x03\x05!\x1B\x03\x02\x02\x02!\x1E\x03\x02\x02\x02\"%\x03\x02\x02\x02" +
		"#!\x03\x02\x02\x02#$\x03\x02\x02\x02$\x05\x03\x02\x02\x02%#\x03\x02\x02" +
		"\x02&\'\x05\b\x05\x02\'(\x07\x1A\x02\x02()\x05\b\x05\x02)C\x03\x02\x02" +
		"\x02*+\x05\b\x05\x02+,\x07\x1B\x02\x02,-\x05\b\x05\x02-C\x03\x02\x02\x02" +
		"./\x05\b\x05\x02/0\x07\x1C\x02\x0201\x05\b\x05\x021C\x03\x02\x02\x022" +
		"3\x05\b\x05\x0234\x07\x1D\x02\x0245\x05\b\x05\x025C\x03\x02\x02\x0267" +
		"\x05\b\x05\x0278\x07\x1E\x02\x0289\x05\b\x05\x029C\x03\x02\x02\x02:;\x05" +
		"\b\x05\x02;<\x07\x1F\x02\x02<=\x05\b\x05\x02=C\x03\x02\x02\x02>?\x05\b" +
		"\x05\x02?@\x07 \x02\x02@A\x05\f\x07\x02AC\x03\x02\x02\x02B&\x03\x02\x02" +
		"\x02B*\x03\x02\x02\x02B.\x03\x02\x02\x02B2\x03\x02\x02\x02B6\x03\x02\x02" +
		"\x02B:\x03\x02\x02\x02B>\x03\x02\x02\x02C\x07\x03\x02\x02\x02DG\x07\x04" +
		"\x02\x02EG\x05\n\x06\x02FD\x03\x02\x02\x02FE\x03\x02\x02\x02G\t\x03\x02" +
		"\x02\x02HI\t\x02\x02\x02I\v\x03\x02\x02\x02JK\t\x03\x02\x02K\r\x03\x02" +
		"\x02\x02\x07\x19!#BF";
	public static __ATN: ATN;
	public static get _ATN(): ATN {
		if (!FoliaParser.__ATN) {
			FoliaParser.__ATN = new ATNDeserializer().deserialize(Utils.toCharArray(FoliaParser._serializedATN));
		}

		return FoliaParser.__ATN;
	}

}

export class CompilationUnitContext extends ParserRuleContext {
	public expression(): ExpressionContext {
		return this.getRuleContext(0, ExpressionContext);
	}
	public EOF(): TerminalNode { return this.getToken(FoliaParser.EOF, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return FoliaParser.RULE_compilationUnit; }
	// @Override
	public enterRule(listener: FoliaParserListener): void {
		if (listener.enterCompilationUnit) {
			listener.enterCompilationUnit(this);
		}
	}
	// @Override
	public exitRule(listener: FoliaParserListener): void {
		if (listener.exitCompilationUnit) {
			listener.exitCompilationUnit(this);
		}
	}
	// @Override
	public accept<Result>(visitor: FoliaParserVisitor<Result>): Result {
		if (visitor.visitCompilationUnit) {
			return visitor.visitCompilationUnit(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class ExpressionContext extends ParserRuleContext {
	public L_PAREN(): TerminalNode | undefined { return this.tryGetToken(FoliaParser.L_PAREN, 0); }
	public expression(): ExpressionContext[];
	public expression(i: number): ExpressionContext;
	public expression(i?: number): ExpressionContext | ExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpressionContext);
		} else {
			return this.getRuleContext(i, ExpressionContext);
		}
	}
	public R_PAREN(): TerminalNode | undefined { return this.tryGetToken(FoliaParser.R_PAREN, 0); }
	public LOGICAL_NOT(): TerminalNode | undefined { return this.tryGetToken(FoliaParser.LOGICAL_NOT, 0); }
	public LOGICAL_AND(): TerminalNode | undefined { return this.tryGetToken(FoliaParser.LOGICAL_AND, 0); }
	public LOGICAL_OR(): TerminalNode | undefined { return this.tryGetToken(FoliaParser.LOGICAL_OR, 0); }
	public relationExpression(): RelationExpressionContext | undefined {
		return this.tryGetRuleContext(0, RelationExpressionContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return FoliaParser.RULE_expression; }
	// @Override
	public enterRule(listener: FoliaParserListener): void {
		if (listener.enterExpression) {
			listener.enterExpression(this);
		}
	}
	// @Override
	public exitRule(listener: FoliaParserListener): void {
		if (listener.exitExpression) {
			listener.exitExpression(this);
		}
	}
	// @Override
	public accept<Result>(visitor: FoliaParserVisitor<Result>): Result {
		if (visitor.visitExpression) {
			return visitor.visitExpression(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class RelationExpressionContext extends ParserRuleContext {
	public atomicExpression(): AtomicExpressionContext[];
	public atomicExpression(i: number): AtomicExpressionContext;
	public atomicExpression(i?: number): AtomicExpressionContext | AtomicExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(AtomicExpressionContext);
		} else {
			return this.getRuleContext(i, AtomicExpressionContext);
		}
	}
	public COMP_EQ(): TerminalNode | undefined { return this.tryGetToken(FoliaParser.COMP_EQ, 0); }
	public COMP_NEQ(): TerminalNode | undefined { return this.tryGetToken(FoliaParser.COMP_NEQ, 0); }
	public COMP_LT(): TerminalNode | undefined { return this.tryGetToken(FoliaParser.COMP_LT, 0); }
	public COMP_GT(): TerminalNode | undefined { return this.tryGetToken(FoliaParser.COMP_GT, 0); }
	public COMP_LEQ(): TerminalNode | undefined { return this.tryGetToken(FoliaParser.COMP_LEQ, 0); }
	public COMP_GEQ(): TerminalNode | undefined { return this.tryGetToken(FoliaParser.COMP_GEQ, 0); }
	public COMP_IN(): TerminalNode | undefined { return this.tryGetToken(FoliaParser.COMP_IN, 0); }
	public arrayExpression(): ArrayExpressionContext | undefined {
		return this.tryGetRuleContext(0, ArrayExpressionContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return FoliaParser.RULE_relationExpression; }
	// @Override
	public enterRule(listener: FoliaParserListener): void {
		if (listener.enterRelationExpression) {
			listener.enterRelationExpression(this);
		}
	}
	// @Override
	public exitRule(listener: FoliaParserListener): void {
		if (listener.exitRelationExpression) {
			listener.exitRelationExpression(this);
		}
	}
	// @Override
	public accept<Result>(visitor: FoliaParserVisitor<Result>): Result {
		if (visitor.visitRelationExpression) {
			return visitor.visitRelationExpression(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class AtomicExpressionContext extends ParserRuleContext {
	public KEY_EXPRESSION(): TerminalNode | undefined { return this.tryGetToken(FoliaParser.KEY_EXPRESSION, 0); }
	public primitiveExpression(): PrimitiveExpressionContext | undefined {
		return this.tryGetRuleContext(0, PrimitiveExpressionContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return FoliaParser.RULE_atomicExpression; }
	// @Override
	public enterRule(listener: FoliaParserListener): void {
		if (listener.enterAtomicExpression) {
			listener.enterAtomicExpression(this);
		}
	}
	// @Override
	public exitRule(listener: FoliaParserListener): void {
		if (listener.exitAtomicExpression) {
			listener.exitAtomicExpression(this);
		}
	}
	// @Override
	public accept<Result>(visitor: FoliaParserVisitor<Result>): Result {
		if (visitor.visitAtomicExpression) {
			return visitor.visitAtomicExpression(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class PrimitiveExpressionContext extends ParserRuleContext {
	public DOUBLE_LITERAL(): TerminalNode | undefined { return this.tryGetToken(FoliaParser.DOUBLE_LITERAL, 0); }
	public INT_LITERAL(): TerminalNode | undefined { return this.tryGetToken(FoliaParser.INT_LITERAL, 0); }
	public BOOLEAN_LITERAL(): TerminalNode | undefined { return this.tryGetToken(FoliaParser.BOOLEAN_LITERAL, 0); }
	public STRING_LITERAL(): TerminalNode | undefined { return this.tryGetToken(FoliaParser.STRING_LITERAL, 0); }
	public NULL_LITERAL(): TerminalNode | undefined { return this.tryGetToken(FoliaParser.NULL_LITERAL, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return FoliaParser.RULE_primitiveExpression; }
	// @Override
	public enterRule(listener: FoliaParserListener): void {
		if (listener.enterPrimitiveExpression) {
			listener.enterPrimitiveExpression(this);
		}
	}
	// @Override
	public exitRule(listener: FoliaParserListener): void {
		if (listener.exitPrimitiveExpression) {
			listener.exitPrimitiveExpression(this);
		}
	}
	// @Override
	public accept<Result>(visitor: FoliaParserVisitor<Result>): Result {
		if (visitor.visitPrimitiveExpression) {
			return visitor.visitPrimitiveExpression(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class ArrayExpressionContext extends ParserRuleContext {
	public DOUBLE_ARRAY_LITERAL(): TerminalNode | undefined { return this.tryGetToken(FoliaParser.DOUBLE_ARRAY_LITERAL, 0); }
	public INT_ARRAY_LITERAL(): TerminalNode | undefined { return this.tryGetToken(FoliaParser.INT_ARRAY_LITERAL, 0); }
	public STRING_ARRAY_LITERAL(): TerminalNode | undefined { return this.tryGetToken(FoliaParser.STRING_ARRAY_LITERAL, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return FoliaParser.RULE_arrayExpression; }
	// @Override
	public enterRule(listener: FoliaParserListener): void {
		if (listener.enterArrayExpression) {
			listener.enterArrayExpression(this);
		}
	}
	// @Override
	public exitRule(listener: FoliaParserListener): void {
		if (listener.exitArrayExpression) {
			listener.exitArrayExpression(this);
		}
	}
	// @Override
	public accept<Result>(visitor: FoliaParserVisitor<Result>): Result {
		if (visitor.visitArrayExpression) {
			return visitor.visitArrayExpression(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


