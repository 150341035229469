import { EditorLanguage } from '../editorLanguage';
import State from '../State';

class FoliaState extends State<EditorLanguage.FOLIA> {
  constructor() {
    super(EditorLanguage.FOLIA);
  }

  clone = () => new FoliaState();
}

export default FoliaState;
